import { FC } from "react";

const Completed: FC<{ active?: boolean }> = ({ active }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58187 0 0 3.58187 0 8C0 12.4181 3.58187 16 8 16C12.4181 16 16 12.4181 16 8C16 3.58187 12.4181 0 8 0ZM12.58 5.62719L12.5772 5.63C12.5759 5.63168 12.5742 5.63298 12.5722 5.63375C12.5702 5.63452 12.568 5.63474 12.5659 5.63438L5.92594 12.2744L3.24156 9.59C3.07282 9.42134 2.97799 9.19255 2.97793 8.95397C2.97787 8.71539 3.07259 8.48656 3.24125 8.31781C3.40991 8.14907 3.6387 8.05424 3.87728 8.05418C4.11586 8.05412 4.34469 8.14884 4.51344 8.3175L5.92531 9.72937L11.2934 4.36188C11.4617 4.19338 11.6897 4.09822 11.9278 4.09713C12.1659 4.09605 12.3948 4.18914 12.5646 4.35611C12.7344 4.52307 12.8313 4.75038 12.8341 4.98847C12.837 5.22657 12.7457 5.45616 12.58 5.62719Z"
        fill={active ? "white" : "#080A25"}
      />
    </svg>
  );
};

export default Completed;
