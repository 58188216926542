import { FC } from "react";
import { domain, getHost } from "utils/domain";

import { Link as ReactRouterLink, LinkProps } from "react-router-dom";

export const Link: FC<LinkProps> = ({ children, to, ...other }) => {
  const host = getHost();
  if (
    window.location.pathname.includes("challenges/") &&
    !(to as string).includes("challenges/") &&
    host !== domain
  ) {
    const hrefWithDomain = window.location.protocol + "//" + domain + to;
    return (
      <a href={hrefWithDomain} {...other}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={to} {...other}>
      {children}
    </ReactRouterLink>
  );
};
