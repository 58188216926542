import { FC } from "react";

const Setting: FC<{ color?: string; className?: string }> = ({
  color = "black",
  className,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.132 13.63C1.95262 12.5507 1.95262 11.4493 2.132 10.37C3.234 10.396 4.224 9.868 4.609 8.939C4.994 8.009 4.667 6.935 3.869 6.176C4.50541 5.28528 5.28456 4.50579 6.175 3.869C6.935 4.667 8.009 4.994 8.939 4.609C9.869 4.224 10.396 3.233 10.369 2.132C11.4489 1.9524 12.5511 1.9524 13.631 2.132C13.604 3.234 14.132 4.224 15.061 4.609C15.991 4.994 17.065 4.667 17.824 3.869C18.7147 4.50541 19.4942 5.28456 20.131 6.175C19.333 6.935 19.006 8.009 19.391 8.939C19.776 9.869 20.767 10.396 21.868 10.369C22.0476 11.4489 22.0476 12.5511 21.868 13.631C20.766 13.604 19.776 14.132 19.391 15.061C19.006 15.991 19.333 17.065 20.131 17.824C19.4946 18.7147 18.7154 19.4942 17.825 20.131C17.065 19.333 15.991 19.006 15.061 19.391C14.131 19.776 13.604 20.767 13.631 21.868C12.5511 22.0476 11.4489 22.0476 10.369 21.868C10.396 20.766 9.868 19.776 8.939 19.391C8.009 19.006 6.935 19.333 6.176 20.131C5.28529 19.4946 4.5058 18.7154 3.869 17.825C4.667 17.065 4.994 15.991 4.609 15.061C4.224 14.131 3.233 13.604 2.132 13.631V13.63ZM12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2043 14.6839 10.4413 14.1213 9.87867C13.5587 9.31607 12.7956 9 12 9C11.2043 9 10.4413 9.31607 9.87868 9.87867C9.31607 10.4413 9 11.2043 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2043 15 12 15V15Z"
        fill={color}
      />
    </svg>
  );
};

export default Setting;
