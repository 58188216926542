import { FC } from "react";

const Users: FC<{ color?: string; className?: string }> = ({
  color = "black",
  className,
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2 22.5C2 20.3783 2.84285 18.3434 4.34315 16.8431C5.84344 15.3429 7.87827 14.5 10 14.5C12.1217 14.5 14.1566 15.3429 15.6569 16.8431C17.1571 18.3434 18 20.3783 18 22.5H2ZM10 13.5C6.685 13.5 4 10.815 4 7.5C4 4.185 6.685 1.5 10 1.5C13.315 1.5 16 4.185 16 7.5C16 10.815 13.315 13.5 10 13.5ZM17.363 15.733C18.8926 16.1261 20.2593 16.9918 21.2683 18.2068C22.2774 19.4218 22.8774 20.9242 22.983 22.5H20C20 19.89 19 17.514 17.363 15.733ZM15.34 13.457C16.178 12.7075 16.8482 11.7893 17.3066 10.7627C17.765 9.73616 18.0013 8.62429 18 7.5C18.0021 6.13347 17.6526 4.78937 16.985 3.597C18.1176 3.82459 19.1365 4.43737 19.8685 5.3312C20.6004 6.22502 21.0002 7.34473 21 8.5C21.0003 9.21247 20.8482 9.91676 20.5541 10.5657C20.26 11.2146 19.8305 11.7931 19.2946 12.2625C18.7586 12.7319 18.1285 13.0814 17.4464 13.2874C16.7644 13.4934 16.0462 13.5512 15.34 13.457Z"
        fill={color}
      />
    </svg>
  );
};

export default Users;
