import axios from "api/axios";
import { IReceivePrizes, ISearchEmail, ISentPrizes } from "./types";

export const followUserRequest = async (userId: string): Promise<any> => {
  const { data } = await axios({
    url: `/user/follow_user`,
    method: "PUT",
    data: {
      followUserId: userId,
    },
  });
  return data;
};

export const unFollowUserRequest = async (userId: string): Promise<any> => {
  const { data } = await axios({
    url: `/user/un_follow_user`,
    method: "PUT",
    data: {
      followUserId: userId,
    },
  });
  return data;
};

export const setFirstTimeLoginRequest = async (): Promise<any> => {
  const { data } = await axios({
    url: `/user/update_first_login`,
    method: "PUT",
  });
  return data;
};

export const searchEmailRequest = async (
  text: string
): Promise<ISearchEmail[]> => {
  const { data } = await axios({
    url: `user/search_by_email_or_username?text=${text}`,
    method: "GET",
  });
  return data;
};

export const disconnectGithub = async (): Promise<any> => {
  const { data } = await axios({
    url: `/auth/disconnect/github`,
    method: "GET",
  });
  return data;
};

export const subscribeDiscussionRequest = async (params: {
  hackathonId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/subscribe_discussions`,
    method: "PUT",
    data: params,
  });
  return data;
};
export const unSubscribeDiscussionRequest = async (params: {
  hackathonId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/unsubscribe_discussions`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const getSentHKTPrizes = async (): Promise<ISentPrizes> => {
  const { data } = await axios({
    url: `/user/total_sent_prize`,
    method: "GET",
  });
  return data;
};

export const getReceiveHKTPrizes = async (): Promise<IReceivePrizes> => {
  const { data } = await axios({
    url: `/user/total_received_prize`,
    method: "GET",
  });
  return data;
};

export const subscribeNewsLetterRequest = async (params: {
  email: string;
}): Promise<any> => {
  const { data } = await axios({
    url: "/hackathon/subscribe",
    method: "POST",
    data: params,
  });
  return data;
};
