import FontFaceObserver from "fontfaceobserver";
import { useLayoutEffect, useState } from "react";

const InterObserver = new FontFaceObserver("Inter", {});
const TekoObserver = new FontFaceObserver("Teko", {});
const SGTObserver = new FontFaceObserver("SGT Jhon O", {});

const timeout = 5000; // miliseconds

export function useFontObserver() {
  const [loaded, setLoaded] = useState(false);

  useLayoutEffect(() => {
    Promise.all([
      InterObserver.load(null, timeout),
      TekoObserver.load(null, timeout),
      SGTObserver.load(null, timeout),
    ])
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        // even if font fail to load, we still need to allow render
        // Delay for CSS loaded
        setTimeout(() => {
          setLoaded(true);
        }, 800);
      });
  }, []);

  return loaded;
}
