import { FC } from "react";
import styles from "./Layout.module.css";
import AntdLayout from "antd/es/layout";

const BlankLayout: FC = ({ children }) => {
  return (
    <AntdLayout className={styles.root}>
      <AntdLayout.Content className={styles.layoutContent}>
        {children}
      </AntdLayout.Content>
    </AntdLayout>
  );
};

export default BlankLayout;
