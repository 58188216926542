import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import {
  getListNotificationRequest,
  checkNotificationRequest,
} from "./request";
import { IListNotification } from "./types";

export const useInfinityListNotification = (
  option?: UseInfiniteQueryOptions<IListNotification, Error>
) => {
  return useInfiniteQuery<IListNotification, Error>(
    "listInfinityNotification",
    ({ pageParam = 1 }) => {
      return getListNotificationRequest(pageParam);
    },
    option
  );
};

export const useCheckNotification = (
  option?: UseQueryOptions<boolean, Error>
) => {
  return useQuery<boolean, Error>(
    "checkNotification",
    checkNotificationRequest,
    option
  );
};
