import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IListParticipantParams } from "api/hackathon";
import { RootState } from "store";

const initialState = {
  sortBy: "Newest",
  search: "",
  skills: [],
  specialty: [],
  teamForm: "",
} as IListParticipantParams;

const filterParticipantSlice = createSlice({
  name: "filterParticipant",
  initialState,
  reducers: {
    setSortBy(state, action: PayloadAction<"Project" | "Follower" | "Newest">) {
      return {
        ...state,
        sortBy: action.payload,
      };
    },
    setSearch(state, action: PayloadAction<string>) {
      return {
        ...state,
        search: action.payload,
      };
    },
    setSpecialty(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        specialty: action.payload,
      };
    },
    setSkills(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        skills: action.payload,
      };
    },
    setTeamForm(state, action: PayloadAction<string>) {
      return {
        ...state,
        teamForm: action.payload,
      };
    },
    clearAllFilter(state) {
      return {
        ...state,
        teamForm: "",
      };
    },
  },
});

export const getParticipantFilter = (
  state: RootState
): IListParticipantParams => {
  return state.filterParticipant;
};

export const getSortBy = (state: RootState): string => {
  return state.filterParticipant.sortBy;
};

export const getSearch = (state: RootState): string => {
  return state.filterParticipant.search;
};

export const getSpecialty = (state: RootState): string[] => {
  return state.filterParticipant.specialty;
};

export const getSkills = (state: RootState): string[] => {
  return state.filterParticipant.skills;
};

export const getTeamForm = (state: RootState): string => {
  return state.filterParticipant.teamForm;
};

export const {
  setSearch,
  setSkills,
  setSortBy,
  setSpecialty,
  setTeamForm,
  clearAllFilter,
} = filterParticipantSlice.actions;
export default filterParticipantSlice.reducer;
