import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import {
  enableRefreshAuth,
  disableRefreshAuth,
  checkShouldRefreshAuth,
} from "utils/authRefresh";

export interface IAuthRefresh {
  needRefresh: boolean;
}

const initialState = {
  needRefresh: checkShouldRefreshAuth(),
} as IAuthRefresh;

const authRefreshSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthRefresh(state, action: PayloadAction<boolean>) {
      if (action.payload) {
        enableRefreshAuth();
      } else {
        disableRefreshAuth();
      }
      return {
        ...state,
        needRefresh: action.payload,
      };
    },
  },
});

export const getAuthRefresh = (state: RootState): boolean =>
  state.auth.needRefresh;

export const getLoginState = (state: RootState): boolean =>
  state.auth.needRefresh;

export const { setAuthRefresh } = authRefreshSlice.actions;
export default authRefreshSlice.reducer;
