import { Suspense } from "react";
import "./App.less";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";

import { Routes } from "pages/Routes";
import { MainLayout } from "components/Layout";
import ScrollToTop from "components/ScrollToTop";
import ContentLoading from "components/Loading/ContentLoading";
import ProgressBar from "components/Loading/ProgressBar";
import { useFontObserver } from "hooks";
import { ErrorBoundary as ErrorFallback } from "components/ErrorBoundary";
import { ErrorBoundary } from "react-error-boundary";
import { Web3Provider } from "@ethersproject/providers";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      staleTime: 1000 * 5,
    },
  },
});

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

const loading = <ProgressBar />;

function App() {
  const fontLoaded = useFontObserver();
  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {!fontLoaded && loading}
        <QueryClientProvider client={queryClient}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <Router>
              <MainLayout>
                <ScrollToTop>
                  <Suspense
                    fallback={
                      <>
                        {loading}
                        <ContentLoading />
                      </>
                    }
                  >
                    <Routes />
                  </Suspense>
                </ScrollToTop>
              </MainLayout>
            </Router>
          </Web3ReactProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </>
  );
}

export default App;
