import axios from "axios";
import { domain } from "utils/domain";

const baseURL = process.env.REACT_APP_API_URI;
axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL,
});

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const callRefreshToken =
      error.response?.data?.message !==
        "Oops! It looks like you haven’t confirmed your email yet! Please check your email and try again" &&
      error.response?.data?.message !== "Invalid email or password";
    if (
      callRefreshToken &&
      error?.response?.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      await axios.post(baseURL + "/auth/refresh_token");
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error?.response?.status === 401 &&
      originalRequest.url.includes("/api/auth/refresh_token")
    ) {
      if (
        window.location.pathname !== "/user/sign-in" &&
        window.location.pathname !== "/projects"
      ) {
        const hrefWithDomain =
          window.location.protocol + "//" + domain + "/user/sign-in";
        window.location.assign(hrefWithDomain);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
