import { FC } from "react";
import styles from "./LoadingFullpage.module.css";
import { Spin } from "antd";
// import { Logo } from "components/Logo/Logo";

// const LoadingFullpage: FC = () => {
//   return (
//     <div className={styles.root}>
//       <Logo className={styles.logo} />
//       <Spin className={styles.spinColor} size="large" />
//     </div>
//   );
// };
const LoadingFullpage: FC = () => {
  return (
    <div className={styles.root}>
      <Spin size="large" />
    </div>
  );
};

export default LoadingFullpage;
