import { FC } from "react";

const Ongoing: FC<{ active?: boolean }> = ({ active }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_781_2572)">
        <path
          d="M12.0692 2.5825C11.5785 2.5825 11.1953 2.18222 11.1953 1.70859C11.1953 1.19332 11.644 0.788009 12.1553 0.839063C12.6095 0.887501 12.9431 1.26262 12.9431 1.70859C12.9431 2.18242 12.5602 2.5825 12.0692 2.5825Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M17.9532 11.0809H17.9536C17.9991 10.6007 18.4256 10.2481 18.9062 10.2935C19.3864 10.3389 19.739 10.7654 19.6936 11.2461C19.6503 11.6983 19.2697 12.0374 18.8245 12.0374C18.3056 12.0374 17.9049 11.592 17.9532 11.0809Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M17.7186 8.24808C17.5936 7.78187 17.8702 7.30297 18.3364 7.17797C18.8027 7.053 19.282 7.32961 19.407 7.79586C19.532 8.26207 19.255 8.74141 18.7891 8.86641C18.7131 8.88652 18.6371 8.89609 18.5623 8.89609C18.1761 8.89609 17.823 8.63875 17.7186 8.24808Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M16.5051 5.682C16.2242 5.28919 16.3147 4.74341 16.707 4.46247C17.0994 4.18196 17.6451 4.27196 17.9261 4.66477C18.207 5.05712 18.1166 5.6029 17.7242 5.88383C17.57 5.99395 17.3926 6.04727 17.2165 6.04727C16.9438 6.04727 16.6756 5.92012 16.5051 5.682Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M14.2308 2.4869C14.5004 2.08662 15.0435 1.98131 15.4438 2.25092C15.844 2.52096 15.9497 3.06412 15.6797 3.46389C15.5111 3.71428 15.2353 3.84928 14.9544 3.84928C14.2604 3.84928 13.8379 3.06912 14.2308 2.4869Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M17.9687 15.0735C17.4878 15.0735 17.0947 14.6823 17.0947 14.1996C17.0947 13.6833 17.5427 13.2792 18.0547 13.33C18.5169 13.3808 18.8426 13.7593 18.8426 14.1996C18.8426 14.6704 18.4607 15.0735 17.9687 15.0735Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M16.8276 15.8668C16.4806 15.5815 15.9739 15.606 15.6557 15.9233C14.2214 17.3537 12.2899 18.1919 10.2466 18.2489C5.85126 18.3717 2.17548 14.8957 2.05271 10.5003C1.96548 7.37819 3.71158 4.53179 6.42442 3.15929L6.70524 4.31058C6.77005 4.57636 7.10126 4.66804 7.29352 4.47343L9.96715 1.76703C10.1501 1.58187 10.0737 1.2689 9.8261 1.18878L6.20657 0.0174552C5.9463 -0.0667635 5.6945 0.167104 5.75935 0.432885L6.00118 1.42433C2.48587 3.02136 0.195407 6.60484 0.305563 10.5491C0.455602 15.9204 4.92364 20.1461 10.2954 19.996C12.7862 19.9265 15.1407 18.905 16.889 17.1617C17.2552 16.7966 17.2271 16.1953 16.8276 15.8668Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M18.806 10.29C18.8905 10.44 18.9319 10.6164 18.9145 10.8009C18.8717 11.2476 18.4997 11.5832 18.0615 11.5914C18.2104 11.8557 18.4932 12.0374 18.8245 12.0374C19.2698 12.0374 19.6504 11.6984 19.6937 11.2461C19.7425 10.7291 19.3318 10.2798 18.806 10.29Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M19.407 7.79591C19.3007 7.39951 18.9382 7.14037 18.5455 7.14837C18.8681 7.72271 18.4627 8.437 17.8008 8.45032C17.9548 8.72509 18.2475 8.89614 18.5623 8.89614C18.637 8.89614 18.7131 8.88654 18.7891 8.86646C19.2549 8.74146 19.5319 8.26212 19.407 7.79591Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M17.9261 4.66473C17.7519 4.42117 17.4759 4.29433 17.1978 4.29973C17.5226 4.87532 17.1112 5.58872 16.4541 5.60138C16.615 5.88716 16.91 6.04728 17.2165 6.04728C17.9213 6.04724 18.3412 5.24454 17.9261 4.66473Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M15.4443 2.25097C15.2889 2.14629 15.1119 2.09879 14.9377 2.1025C15.0942 2.3814 15.0921 2.73578 14.901 3.01867C14.7363 3.2632 14.4694 3.39734 14.1953 3.40347C14.3412 3.66238 14.6154 3.84933 14.9549 3.84933C15.2358 3.84933 15.5116 3.71433 15.6802 3.46394C15.9503 3.06418 15.8445 2.52101 15.4443 2.25097Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M18.0546 13.3298C18.0196 13.3263 17.9849 13.3252 17.9506 13.3259C18.0225 13.4536 18.0632 13.5998 18.0632 13.7541C18.0632 14.2192 17.6905 14.618 17.2071 14.6276C17.3573 14.8936 17.6426 15.0733 17.9685 15.0733C18.4605 15.0733 18.8424 14.6702 18.8424 14.1994C18.8424 13.7592 18.5167 13.3806 18.0546 13.3298Z"
          fill={active ? "white" : "#080A25"}
        />
        <path
          d="M16.8273 15.8667C16.6537 15.724 16.4403 15.6588 16.2305 15.669C16.4307 16.0028 16.3904 16.4461 16.0974 16.7384C14.349 18.4816 11.9945 19.5031 9.50379 19.5727C7.91137 19.6172 6.39836 19.277 5.05371 18.6366C6.58344 19.5474 8.3818 20.0494 10.2951 19.996C12.7858 19.9264 15.1403 18.9049 16.8887 17.1617C17.2549 16.7965 17.2268 16.1952 16.8273 15.8667Z"
          fill={active ? "white" : "#080A25"}
        />
      </g>
      <defs>
        <clipPath id="clip0_781_2572">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ongoing;
