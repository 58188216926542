import { FC } from "react";
import styles from "./styles.module.css";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { Logo } from "components/Logo";
import { routesEnum } from "pages/Routes";
import { Link } from "components/Link";
import { Hidden } from "components/Hidden";
import SideBar from "./SideBar";
import { useAppDispatch, useAppSelector } from "hooks";
import { getAuthRefresh, setAuthRefresh } from "store/ducks/auth/slice";
import UserDropdown from "./UserDropdown";
import Notification from "./Notification";
import clsx from "clsx";
import { Space } from "antd";
import { useUser } from "api/auth";
import { useHistory } from "react-router-dom";
import { WalletModal } from "components/WalletModal";
import { useEagerConnect, useInactiveListener } from "hooks/web3Hooks";
import { BLOG_URL } from "utils/domain";

const Header: FC = () => {
  const isLoggedIn = useAppSelector(getAuthRefresh);

  const history = useHistory();
  const dispatch = useAppDispatch();

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager);

  useUser({
    enabled: isLoggedIn,
    onSuccess: (data) => {
      if (data.isFirstLogin) {
        history.push(routesEnum.updateEligibility);
      }
      dispatch(setAuthRefresh(true));
    },
    onError: () => {
      dispatch(setAuthRefresh(false));
    },
  });

  const { md } = useBreakpoint();

  return (
    <>
      <nav className={styles.nav}>
        <div className={clsx(styles.root, "container-lg")}>
          <div className={styles.navList}>
            <Hidden js cond={md}>
              <Link to={routesEnum.home}>Hackathons</Link>
              <Link to={routesEnum.blog}>Blog</Link>
              <Link to={routesEnum.software}>Projects</Link>
            </Hidden>
          </div>
          <div className={styles.logo}>
            <Link to={routesEnum.landingPage}>
              <Logo />
            </Link>
          </div>
          <div className={styles.navList}>
            <Hidden js cond={!md}>
              <SideBar />
            </Hidden>

            <Hidden js cond={md}>
              {!isLoggedIn ? (
                <>
                  <Link to={routesEnum.signIn}>Login</Link>

                  <Link to={routesEnum.signUp} className={styles.btnSignUp}>
                    Sign Up
                  </Link>
                </>
              ) : (
                <>
                  <Space size={24}>
                    <Link to={routesEnum.createHackathon}>
                      Host a Hackathon
                    </Link>

                    <Notification />
                    <div className={styles.userDropdown}>
                      <UserDropdown />
                    </div>
                  </Space>
                </>
              )}
            </Hidden>
          </div>
        </div>
      </nav>
      <WalletModal />
    </>
  );
};

export default Header;
