import { FC, memo, useMemo } from "react";
import styles from "./UserDropdown.module.css";
import { Button, Dropdown, Menu, Progress, Tooltip } from "antd";

import { routesEnum } from "pages/Routes";

import { useQueryClient } from "react-query";
import { useAppDispatch, useDomainRoute } from "hooks";
import { setAuthRefresh } from "store/ducks/auth/slice";
import { logoutRequest, useUser } from "api/auth";

import { Link } from "components/Link";
import { Avatar } from "components/Avatar";
import { getImageUrl } from "utils/urlParser";
import Wallet from "./Wallet";
import clsx from "clsx";

const UserDropdown: FC<{ hideDot?: boolean }> = ({ hideDot }) => {
  const [navigateTo] = useDomainRoute();
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { data: me } = useUser({});

  const handleLogout = async () => {
    dispatch(setAuthRefresh(false));
    await logoutRequest();
    queryClient.clear();
    navigateTo(routesEnum.signIn);
  };

  const completeProfilePercent = useMemo(() => {
    let percent = 100;
    if (!me?.avatar_link) percent -= 10;
    if (!me?.firstName) percent -= 10;
    if (!me?.lastName) percent -= 10;
    const preferences = me?.preferencesEligibility;

    if (!preferences?.specialty) percent -= 10;
    if (preferences?.skills?.length === 0) percent -= 10;
    if (preferences?.typesInterested?.length === 0) percent -= 10;
    if (!preferences?.location) percent -= 10;
    if (!preferences?.occupation) percent -= 10;
    if (!preferences?.birthMonth) percent -= 10;

    return percent;
  }, [me]);

  const percentInfo = useMemo(() => {
    if (!me?.avatar_link) return "Missing avatar";

    if (!me?.firstName) return "Missing first name";

    if (!me?.lastName) return "Missing last name";
    const preferences = me?.preferencesEligibility;

    if (!preferences?.specialty) return "Missing specialty";
    if (preferences?.skills?.length === 0) return "Missing skills";
    if (preferences?.typesInterested?.length === 0)
      return "Missing types interested";
    if (!preferences?.location) return "Missing location";
    if (!preferences?.occupation) return "Missing occupation";
    if (!preferences?.birthMonth) return "Missing date of birth";

    return "";
  }, [me]);

  const username = useMemo(() => {
    if (!me?.firstName) return me?.userName;

    return me.firstName + " " + (me.lastName || "");
  }, [me]);

  return (
    <Dropdown
      overlay={
        <div className={styles.root}>
          <div className={styles.infoWrapper}>
            <h3 className="lineClamp">{username}</h3>
            <Wallet />
          </div>

          <div className={styles.completeWrapper}>
            <Tooltip title={percentInfo}>
              <Link to={routesEnum.settings} className="text-20 m-0 black">
                Complete your profile
              </Link>
            </Tooltip>
            <Progress
              percent={completeProfilePercent}
              className={styles.progress}
            />
          </div>
          <Menu className={styles.menu}>
            <Menu.Item key="Manage">
              <Link to={routesEnum.manageHackathon}>Manage Hackathons</Link>
            </Menu.Item>
            <Menu.Item key="payment">
              <Link to={routesEnum.payment}>Payment History</Link>
            </Menu.Item>
            <Menu.Item key="Portpolio">
              <Link to={`${routesEnum.portfolio}/${me?.userName}`}>
                Profile
              </Link>
            </Menu.Item>
            <Menu.Item key="Setting">
              <Link to={routesEnum.settings}>Setting</Link>
            </Menu.Item>
            <Menu.Item key="Help">
              <Link to={routesEnum.help}>Help</Link>
            </Menu.Item>
            {/* <Menu.Item key="Help">
              <Link to="/help">Help</Link>
            </Menu.Item> */}
          </Menu>
          <div className={styles.logoutWrapper}>
            <Button type="text" danger onClick={handleLogout}>
              Log Out
            </Button>
          </div>
        </div>
      }
      placement="bottomRight"
    >
      <div
        className={clsx(styles.avatar, {
          [styles.hideDot]: true,
        })}
      >
        <Avatar size={37} src={getImageUrl(me?.avatar_link)} />
      </div>
    </Dropdown>
  );
};

export default memo(UserDropdown);
