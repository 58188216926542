import { FC } from "react";
import styles from "./LocalLoading.module.css";
import { Skeleton } from "antd";
import clsx from "clsx";

export const LocalLoading: FC = () => {
  return (
    <div className={clsx(styles.root, "container")}>
      <Skeleton active paragraph={{ rows: 8 }} />
    </div>
  );
};
