import { FC } from "react";
import styles from "./styles.module.css";
import clsx from "clsx";

export const Hidden: FC<{ cond?: boolean; js?: boolean }> = ({
  cond,
  js,
  children,
}) => {
  if (js) {
    return cond ? <>{children}</> : null;
  }

  return (
    <div className={clsx(!cond && styles.hidden, styles.root)}>{children}</div>
  );
};
