import { FC } from "react";
import styles from "./ContentLoading.module.css";

import { Spin } from "antd";
import clsx from "clsx";

const ContentLoading: FC = () => {
  return (
    <div className={clsx(styles.root, "container")}>
      <Spin size="large" />
    </div>
  );
};

export default ContentLoading;
