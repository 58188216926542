const baseURL = process.env.REACT_APP_API_URI;

/**
 * * Parse the url including embed video IFrame. Youtube url ....
 * @param url
 * @returns boolean | youtube video id
 */

export interface IVideoEmbed {
  id?: string;
  type?: "youtube" | "vimeo";
}
export const videoUrlParser = (url: string): IVideoEmbed => {
  if (!url) return { type: "youtube", id: "" };

  // Parse youtube url id
  if (url.includes("youtu")) {
    if (url.includes("</iframe>")) {
      const regexWithEmbed = /youtube.com\/embed\/([A-Za-z0-9-]+)/;
      const matchWithEmbed = url.match(regexWithEmbed);
      const id =
        matchWithEmbed && matchWithEmbed[1].length == 11
          ? matchWithEmbed[1]
          : "";
      return { type: "youtube", id };
    }
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    const id = match && match[7].length == 11 ? match[7] : "";
    return { type: "youtube", id };
  }

  // Parse vimeo url id
  if (url.includes("vimeo")) {
    const match = url.match(
      /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/i
    );
    const id = match && match?.length > 0 ? match[1] : "";
    return { type: "vimeo", id };
  }

  // Fallback if nothing go right
  return { type: "youtube", id: "" };
};

/**
 *
 * @param path
 * @returns server image public url if exist
 */
export const getImageUrl = (path: any) => {
  if (!path) return "";
  // Begin with http:// or https://
  const regex = /^(?:http(s)?:\/\/)/;
  return regex.test(path) ? path : `${baseURL}/upload/get_file/${path}`;
};

/**
 *
 * @param key
 * @param arr
 * @returns string
 */
export const parameterizeArray = (key: string, arr?: string[]): string => {
  if (!arr || (arr && arr.length === 0)) return "";
  arr = arr.map(encodeURIComponent);
  return "&" + key + "[]=" + arr.join("&" + key + "[]=");
};
