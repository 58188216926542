import { useHistory } from "react-router";
import { domain, getHost } from "utils/domain";

export const useDomainRoute = () => {
  const history = useHistory();

  const push = (href: string) => {
    const host = getHost();
    if (window.location.pathname.includes("challenges/") && host !== domain) {
      const hrefWithDomain = window.location.protocol + "//" + domain + href;
      // change url in the address bar and save it in the history
      window.location.assign(hrefWithDomain);
    } else {
      history.push(href);
    }
  };
  return [push];
};
