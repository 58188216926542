import { FC } from "react";

const Flag: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 3H21.138C21.2256 2.99985 21.3118 3.02275 21.3878 3.06639C21.4638 3.11002 21.527 3.17288 21.5711 3.24864C21.6151 3.3244 21.6385 3.41042 21.6388 3.49806C21.6392 3.5857 21.6165 3.6719 21.573 3.748L18 10L21.573 16.252C21.6165 16.3281 21.6392 16.4143 21.6388 16.5019C21.6385 16.5896 21.6151 16.6756 21.5711 16.7514C21.527 16.8271 21.4638 16.89 21.3878 16.9336C21.3118 16.9773 21.2256 17.0001 21.138 17H4V22H2V3Z"
        fill="black"
      />
    </svg>
  );
};

export default Flag;
