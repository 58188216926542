import { useMemo } from "react";
import { Contract } from "@ethersproject/contracts";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";

interface IEthersContract<T> extends Web3ReactContextInterface<Web3Provider> {
  contract: T | null;
}

export function useContract<T extends Contract = Contract>(
  address: string,
  ABI: any
): IEthersContract<T> {
  const { library, account, chainId, ...other } = useWeb3React<Web3Provider>();

  const contract = useMemo(() => {
    if (!address || !ABI || !library || !chainId || !account) {
      return null;
    }

    try {
      return new Contract(address, ABI, library.getSigner(account));
    } catch (error) {
      console.error("Failed To Get Contract", error);

      return null;
    }
  }, [address, ABI, library, account]) as T | null;

  return { contract, library, account, chainId, ...other };
}

export function useContractNoSigner<T extends Contract = Contract>(
  address: string,
  ABI: any
): T {
  return useMemo(() => {
    return new Contract(address, ABI);
  }, [address, ABI]) as T;
}
