import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface IWalletModal {
  openModal: boolean;
}

const initialState = {
  openModal: false,
} as IWalletModal;

const walletModalSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    toggleWalletModal: (state) => {
      return {
        openModal: !state.openModal,
      };
    },
    closeWalletModal: () => {
      return {
        openModal: false,
      };
    },
  },
});

export const getWalletModalState = (state: RootState): boolean =>
  state.walletModal.openModal;

export const { toggleWalletModal, closeWalletModal } = walletModalSlice.actions;
export default walletModalSlice.reducer;
