import { useQuery, UseQueryOptions } from "react-query";
import { getUserByIdRequest, getUserRequest } from "./request";
import { IUserData } from "./types";

export const useUser = (option?: UseQueryOptions<IUserData, Error>) => {
  return useQuery<IUserData, Error>("me", getUserRequest, option);
};

export const useUserWithId = (
  userId: string,
  option?: UseQueryOptions<IUserData, Error>
) => {
  return useQuery<IUserData, Error>(
    ["user", userId],
    () => getUserByIdRequest(userId),
    option
  );
};
