import clsx from "clsx";
import { useCallback } from "react";
import { useState } from "react";
import { FC, memo } from "react";
import styles from "./SettingSelection.module.css";

export enum ISettings {
  profile = "profile",
  preferences = "preferences",
  emailNotify = "email-notifications",
  account = "account-privacy",
  password = "password",
}

const SettingSelection: FC<{
  active?: ISettings;
  onChange: (value: ISettings) => void;
}> = ({ active, onChange }) => {
  const handleClick = useCallback((setting: ISettings) => {
    onChange(setting);
  }, []);

  return (
    <div>
      <h3 className={styles.title}>Portfolio</h3>
      <ul className={styles.listSetting}>
        <li
          onClick={() => handleClick(ISettings.profile)}
          className={clsx(active === ISettings.profile && styles.active)}
        >
          Profile info
        </li>
      </ul>

      <h3 className={styles.title}>Hackathon Recommendations</h3>
      <ul className={styles.listSetting}>
        <li
          onClick={() => handleClick(ISettings.preferences)}
          className={clsx(active === ISettings.preferences && styles.active)}
        >
          Preferences & Eligibility
        </li>
      </ul>

      <h3 className={styles.title}>Account Management</h3>
      <ul className={styles.listSetting}>
        <li
          onClick={() => handleClick(ISettings.emailNotify)}
          className={clsx(active === ISettings.emailNotify && styles.active)}
        >
          Email notifications
        </li>
        <li
          onClick={() => handleClick(ISettings.account)}
          className={clsx(active === ISettings.account && styles.active)}
        >
          Account & privacy
        </li>
        <li
          onClick={() => handleClick(ISettings.password)}
          className={clsx(active === ISettings.password && styles.active)}
        >
          Password
        </li>
      </ul>
    </div>
  );
};

export default memo(SettingSelection);
