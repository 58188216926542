import { FC, useEffect, useLayoutEffect } from "react";
import loadable from "@loadable/component";
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "utils/PrivateRoutes";
// import { MainLayout } from "components/Layout";

// import { LoadingFullpage } from "components/Loading";

export enum hackathonDetailParams {
  overview = "overview",
  myProject = "my-project",
  participants = "participants",
  resources = "resources",
  rules = "rules",
  projectGallery = "project-gallery",
  updates = "updates",
  discussions = "discussions",
  judge = "judge",
  schedule = "schedule",
  submission = "submission",
  invitation = "invitation",
}

export enum selectHackathonPlanParams {
  inPerson = "in-person-hackathon",
  student = "student-hackathon",
  online = "online-hackathon",
}

export enum manageHackathonTab {
  edit = "edit",
  update = "update",
  discussion = "discussion",
  dashboard = "dashboard",
}

export enum editHackathonParams {
  essentials = "essentials",
  eligibility = "eligibility",
  design = "design",
  hackathonSite = "hackathon-site",
  todos = "todos",
  starterKit = "starter-kit",
  submissions = "submissions",
  publicVoting = "public-voting",
  judging = "judging",
  prizes = "prizes",
}

export enum dashboardHackathonParams {
  metrics = "metrics",
  submissions = "submissions",
  judgingVoting = "judging_voting",
  selectWinners = "select_winners",
  announceWinners = "announce_winners",
  sendPrizes = "send_prizes",
}

// Test concurrency for master
// To navigate from outside a componentality
export enum routesEnum {
  landingPage = "/",
  home = "/hackathon",
  signIn = "/user/sign-in",
  signUp = "/user/sign-up",
  resetPassword = "/user/reset-password",
  changePassword = "/user/change-password-token",
  verifyEmail = "/user/verify-email",
  verifyChangeEmail = "/user/verify-new-email",
  confirmFollow = "/user/follow_user",
  acceptRequestFollow = "/user/accept_request_follow_user",
  dismissRequestFollow = "/user/dismiss_request_follow_user",
  createHackathon = "/hackathon/new",
  editHackathon = "/hackathon/manage",
  manageHackathon = "/hackathon/list",
  editRegistrationForm = "/hackathon/registration-form",
  editSubmissionForm = "/hackathon/submission-form",
  updateEligibility = "/hackathon-recommendations",
  editProject = "/project/edit",
  project = "/project",
  hackathon = "/challenges",
  registerHackathon = "/register",
  importProject = "/import-to",
  settings = "/settings",
  portfolio = "/portfolio",
  software = "/projects",
  help = "/help",
  helpCategories = "/help/categories",
  helpArticle = "/help/article",
  about = "/about",
  contact = "/contact",
  privacy = "/privacy",
  printJudgingSheet = "/print_judging_sheets",
  content = "/content",
  payment = "/payment",
  term = "/term",
  policy = "/policy",
  blog = "/blog",
  notFound = "/404",
}

type IRoutes = Partial<RouteProps> & {
  isPrivate?: boolean;
};

const HomePage = loadable(
  () => import(/* webpackPrefetch: true */ "./HomePage")
);
const BlogPage = loadable(() => import(/* webpackPrefetch: true */ "./Blog"));
const BlogDetailPage = loadable(
  () => import(/* webpackPrefetch: true */ "./BlogDetail")
);
const SignInPage = loadable(() => import("./SignInPage"));
const SignUpPage = loadable(() => import("./SignUpPage"));
const ResetPasswordPage = loadable(() => import("./ResetPasswordPage"));
const ChangePasswordPage = loadable(() => import("./ChangePasswordPage"));
const EmailVeificationPage = loadable(() => import("./EmailVeificationPage"));
const ConfirmChangeEmailPage = loadable(
  () => import("./ConfirmChangeEmailPage")
);
const ConfirmFollowUserPage = loadable(() => import("./ConfirmFollowUserPage"));
const AcceptRequestFollowUserPage = loadable(
  () => import("./AcceptRequestFollowUserPage")
);
const DismissRequestFollowUserPage = loadable(
  () => import("./DismissRequestFollowUserPage")
);
const HackathonDetailPage = loadable(
  () => import(/* webpackPrefetch: true */ "./HackathonDetailPage")
);
const CreateHackathonPage = loadable(() => import("./CreateHackathonPage"));
const ManageHackathonPage = loadable(() => import("./ManageHackathonPage"));
const EditHackathonPage = loadable(() => import("./EditHackathonPage"));
const RegisterHackathonPage = loadable(() => import("./RegisterHackathonPage"));
const ImportProjectPage = loadable(() => import("./ImportProjectPage"));
const EditRegistrationPage = loadable(() => import("./EditRegistrationPage"));
const UpdateEligibilityPage = loadable(() => import("./UpdateEligibilityPage"));
const SettingPage = loadable(() => import("./SettingPage"));
const PortfolioPage = loadable(() => import("./PortfolioPage"));
const EditProjectPage = loadable(() => import("./EditProjectPage"));
const ProjectDetailPage = loadable(() => import("./ProjectDetailPage"));
const SoftwarePage = loadable(() => import("./SoftwarePage"));
const HelpPage = loadable(() => import("./HelpPage"));
const HelpCategoryPage = loadable(() => import("./HelpCategoryPage"));
const HelpArticlePage = loadable(() => import("./HelpArticlePage"));
const AboutPage = loadable(() => import("./AboutPage"));
const ContactPage = loadable(() => import("./ContactPage"));
const PrivacyPolicyPage = loadable(() => import("./PrivacyPolicyPage"));
const PrintJudgingSheetPage = loadable(() => import("./PrintJudgingSheetPage"));
const Content = loadable(() => import("./Content"));
const HistoryPayment = loadable(() => import("./HistoryPayment"));
const LandingPage = loadable(
  () => import(/* webpackPrefetch: true */ "./LandingPage")
);
const PolicyPage = loadable(() => import("./PolicyPage"));
const TermAndConditionPage = loadable(() => import("./TermAndConditionPage"));
const NotFoundPage = loadable(() => import("./404"));

const routes: IRoutes[] = [
  {
    path: routesEnum.home,
    exact: true,
    component: HomePage,
  },
  {
    path: routesEnum.blog,
    exact: true,
    component: BlogPage,
  },
  {
    path: `${routesEnum.blog}/:id`,
    exact: true,
    component: BlogDetailPage,
  },
  {
    path: routesEnum.signIn,
    exact: true,
    component: SignInPage,
  },
  {
    path: routesEnum.signUp,
    exact: true,
    component: SignUpPage,
  },
  {
    path: routesEnum.resetPassword,
    exact: true,
    component: ResetPasswordPage,
  },
  {
    path: routesEnum.changePassword,
    exact: true,
    component: ChangePasswordPage,
  },
  {
    path: routesEnum.verifyEmail,
    exact: true,
    component: EmailVeificationPage,
  },
  {
    path: routesEnum.verifyChangeEmail,
    exact: true,
    component: ConfirmChangeEmailPage,
  },
  {
    path: routesEnum.confirmFollow,
    exact: true,
    component: ConfirmFollowUserPage,
  },
  {
    path: routesEnum.acceptRequestFollow,
    exact: true,
    component: AcceptRequestFollowUserPage,
  },
  {
    path: routesEnum.dismissRequestFollow,
    exact: true,
    component: DismissRequestFollowUserPage,
  },
  {
    path: `/:name${routesEnum.hackathon}/:tab?/:id?/:extend?`,
    exact: true,
    component: HackathonDetailPage,
  },
  {
    path: `${routesEnum.createHackathon}/:plan?`,
    exact: true,
    component: CreateHackathonPage,
    isPrivate: true,
  },
  {
    path: routesEnum.manageHackathon,
    exact: true,
    component: ManageHackathonPage,
    isPrivate: true,
  },
  {
    path: `${routesEnum.editHackathon}/:id/:tab?`,
    exact: true,
    component: EditHackathonPage,
    isPrivate: true,
  },
  {
    path: `/:name${routesEnum.registerHackathon}/:id`,
    exact: true,
    component: RegisterHackathonPage,
    isPrivate: true,
  },
  {
    path: `/:name${routesEnum.importProject}/:id`,
    exact: true,
    component: ImportProjectPage,
    isPrivate: true,
  },
  {
    path: `${routesEnum.editRegistrationForm}/:id`,
    exact: true,
    component: EditRegistrationPage,
    isPrivate: true,
  },
  // {
  //   path: `${routesEnum.editSubmissionForm}/:id`,
  //   exact: true,
  //   component: loadable(() => import("./EditSubmissionPage")),
  //   isPrivate: true,
  // },
  {
    path: routesEnum.updateEligibility,
    exact: true,
    component: UpdateEligibilityPage,
  },
  {
    path: routesEnum.settings,
    exact: true,
    component: SettingPage,
    isPrivate: true,
  },
  {
    path: `${routesEnum.portfolio}/:username`,
    exact: true,
    component: PortfolioPage,
  },
  {
    path: `${routesEnum.editProject}/:id`,
    exact: true,
    component: EditProjectPage,
    isPrivate: true,
  },
  {
    path: `${routesEnum.project}/:id/:extend?`,
    exact: true,
    component: ProjectDetailPage,
  },
  {
    path: routesEnum.software,
    exact: true,
    component: SoftwarePage,
  },
  {
    path: routesEnum.help,
    exact: true,
    component: HelpPage,
  },
  {
    path: `${routesEnum.helpCategories}/:category`,
    exact: true,
    component: HelpCategoryPage,
  },
  {
    path: `${routesEnum.helpArticle}/:group/:category/:article`,
    exact: true,
    component: HelpArticlePage,
  },
  {
    path: routesEnum.about,
    exact: true,
    component: AboutPage,
  },
  {
    path: routesEnum.contact,
    exact: true,
    component: ContactPage,
  },
  {
    path: routesEnum.privacy,
    exact: true,
    component: PrivacyPolicyPage,
  },
  {
    path: `${routesEnum.printJudgingSheet}/:id`,
    exact: true,
    component: PrintJudgingSheetPage,
  },
  {
    path: `${routesEnum.content}`,
    exact: true,
    component: Content,
  },
  {
    path: `${routesEnum.payment}`,
    exact: true,
    component: HistoryPayment,
  },
  {
    path: `${routesEnum.landingPage}`,
    exact: true,
    component: LandingPage,
  },
  {
    path: `${routesEnum.term}`,
    exact: true,
    component: TermAndConditionPage,
  },
  {
    path: `${routesEnum.policy}`,
    exact: true,
    component: PolicyPage,
  },
  {
    path: routesEnum.notFound,
    exact: false,
    component: NotFoundPage,
  },
];

export const Routes: FC = () => {
  const { pathname, hash } = useLocation();

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (hash) {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) {
          const box = element.getBoundingClientRect();
          const body = document.body;
          const docEl = document.documentElement;

          const scrollTop =
            window.pageYOffset || docEl.scrollTop || body.scrollTop;

          const clientTop = docEl.clientTop || body.clientTop || 0;

          const top = box.top + scrollTop - clientTop;
          window.scrollTo({
            behavior: "smooth",
            top: top - 100,
          });
        }
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [pathname, hash]);

  return (
    <Switch>
      {routes.map((route) => {
        return route.isPrivate ? (
          <PrivateRoute
            path={route.path}
            key={route.path as string}
            exact={route.exact}
            component={route.component}
          />
        ) : (
          <Route
            path={route.path}
            key={route.path as string}
            exact={route.exact}
            component={route.component}
          />
        );
      })}
      <Redirect from="*" to="/404" />
    </Switch>
  );
};
