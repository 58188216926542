import { forwardRef, PropsWithChildren } from "react";
import styles from "./styles.module.css";
import clsx from "clsx";
import { Button as ButtonAntd, ButtonProps } from "antd";

type CustomButtonProps = Partial<ButtonProps> & {
  color?:
    | "primary"
    | "info"
    | "gradient"
    | "gradient_2"
    | "gradient_3"
    | "transparent"
    | "default"
    | "secondary"
    | "outlined"
    | "none"
    | "send"
    | "view";
  htmlType?: "submit" | "button" | "reset";
  rounded?: boolean;
};

const GradientButton = forwardRef<
  HTMLElement,
  PropsWithChildren<CustomButtonProps>
>(function GradientButton(
  {
    children,
    htmlType = "button",
    color = "primary",
    rounded,
    className,
    size = "middle",
    ...props
  },
  ref
) {
  return (
    <ButtonAntd
      htmlType={htmlType}
      className={clsx(styles.root, styles[color], styles[size], className)}
      ref={ref}
      {...props}
    >
      {children}
    </ButtonAntd>
  );
});

export default GradientButton;
