import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import { message } from "antd";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorWalletConnect } from "@web3-react/walletconnect-connector";
import { Web3Provider } from "@ethersproject/providers";

import { injected, walletconnect, walletlink } from "utils/walletUtils";

const handleError = (error: Error) => {
  if (error instanceof NoEthereumProviderError) {
    message.error(
      "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
    );
  } else if (error instanceof UnsupportedChainIdError) {
    message.error("You're connected to an unsupported network.");
  } else if (
    error instanceof UserRejectedRequestErrorInjected ||
    error instanceof UserRejectedRequestErrorWalletConnect
  ) {
    message.error(
      "Please authorize this website to access your Ethereum account."
    );
  } else if (error.message.includes("Already processing eth_requestAccount")) {
    message.error("Already processing eth_requestAccounts. Please wait.");
  } else {
    console.error(error);
    message.error(
      "An unknown error occurred. Check the console for more details."
    );
  }
};

export const useWallet = () => {
  const { activate, connector, ...props } = useWeb3React<Web3Provider>();

  const connect = async (type: "injected" | "walletconnect" | "walletlink") => {
    try {
      if (type === "injected") {
        const { ethereum } = window as any;
        if (!ethereum) {
          return message.error(
            "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile."
          );
        }
        return await activate(injected, (error) => handleError(error));
      }

      if (type === "walletconnect") {
        return await activate(walletconnect, (error) => handleError(error));
      }

      if (type === "walletlink") {
        return await activate(walletlink, (error) => handleError(error));
      }
    } catch (err) {
      console.log("Connect wallet", err);
    }
  };

  return { ...props, connector, connect };
};
