import { FC } from "react";

const Bell: FC<{ active?: boolean; className?: string }> = ({
  active,
  className,
}) => {
  return active ? (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M15 7.0708C15 5.74472 14.4732 4.47295 13.5355 3.53527C12.5979 2.59758 11.3261 2.0708 10 2.0708C8.67392 2.0708 7.40215 2.59758 6.46447 3.53527C5.52678 4.47295 5 5.74472 5 7.0708C5 12.9041 2.5 14.5708 2.5 14.5708H17.5C17.5 14.5708 15 12.9041 15 7.0708Z"
        fill="#102548"
      />
      <path
        d="M14.1673 15.8335C13.7439 16.5939 13.1361 17.2251 12.4049 17.6638C11.6736 18.1026 10.8445 18.3335 10.0007 18.3335C9.15677 18.3335 8.3277 18.1026 7.59644 17.6638C6.86519 17.2251 6.25742 16.5939 5.83398 15.8335"
        fill="#102548"
      />
    </svg>
  ) : (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 9.89941C21 8.0429 20.2625 6.26242 18.9497 4.94967C17.637 3.63691 15.8565 2.89941 14 2.89941C12.1435 2.89941 10.363 3.63691 9.05025 4.94967C7.7375 6.26242 7 8.0429 7 9.89941C7 18.0661 3.5 20.3994 3.5 20.3994H24.5C24.5 20.3994 21 18.0661 21 9.89941Z"
        fill="white"
      />
      <path
        d="M17.7735 3.982V3.058H19.5515V8H18.5155V3.982H17.7735Z"
        fill="black"
      />
      <path
        d="M19.8327 22.167C19.2399 23.2315 18.389 24.1152 17.3652 24.7294C16.3415 25.3437 15.1808 25.667 13.9993 25.667C12.8179 25.667 11.6572 25.3437 10.6335 24.7294C9.60971 24.1152 8.75883 23.2315 8.16602 22.167"
        fill="white"
      />
    </svg>
  );
};

export default Bell;
