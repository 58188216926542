import { NETWORK_CHAIN_IDS } from "./walletUtils";

export const POLYGON_RPC = process.env.REACT_APP_POLYGON_RPC!;
export const AURORA_RPC = process.env.REACT_APP_AURORA_RPC!;
export const ETH_RPC = process.env.REACT_APP_ETH_RPC!;
export const USE_TESTNET = process.env.REACT_APP_USE_TESTNET! === "true";

export const CHAIN_BY_NETWORK_KEYS: Record<string, number> = USE_TESTNET
  ? {
      Ethereum: NETWORK_CHAIN_IDS.rinkeby,
      Polygon: NETWORK_CHAIN_IDS.mumbai,
      Aurora: NETWORK_CHAIN_IDS.auroraTest,
    }
  : {
      Ethereum: NETWORK_CHAIN_IDS.mainnet,
      Polygon: NETWORK_CHAIN_IDS.polygon,
      Aurora: NETWORK_CHAIN_IDS.aurora,
    };
