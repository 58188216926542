import { IUserData } from "api/auth";
import { IMasterTag } from "api/master";
import { IComment, IProject } from "api/project";

export const LIST_HACKATHON_PAGE_SIZE = 20;
export const LIST_GALLERY_PAGE_SIZE = 20;

export interface ICreateHackathonParams {
  name: string;
  startDate: number;
}

export interface IUpdateEssentialParams {
  name: string;
  url: string;
  tagLine: string;
  mailManage: string;
  host: string;
  themeTags: string[];
  types:
    | "Internal-Offline"
    | "Internal-Online/Virtual"
    | "Public-Offline"
    | "Public-Online/Virtual";
  hostType: string;
  timeZone: string;
  locationName: string;
  locationAddress: string;
}

export interface IUpdateEligibilityParams {
  community: "Public" | "Invite only";
  inviteParticipate: string;
  participantAge: "legalAge" | "limitMinMax" | "limitMinOnly";
  limitAge: {
    min: number;
    max?: number;
  };
  limitTeamSize: {
    min: number;
    max: number;
  };
  participantOccupation: "allOccupations" | "studentsOnly" | "professional";
  studentOccupation: string;
  teamRequirements: string[];
  companyRequirements: string;
  geographicRestrictions:
    | "allCountry"
    | "specificCountryInclude"
    | "specificCountryExclude"
    | "usOnly";
  geographicIncluded: string[];
  geographicExcluded: string[];
  teamRequiredType: "teamSize" | "allTeam";
}
export interface IUpdateDesignParams {
  thumbnailImage: string;
  logoImage: string;
  logoAlignment: string;
  textTitleColor: string;
  backgroundImage: string;
  backgroundPosition: string;
  backgroundColor: string;
}
export interface IUpdateJudgingParams {
  judgingType: string;
  judgingDate: {
    startDate: any;
    endDate: any;
  };
}

export interface ISponsor {
  _id: string;
  name: string;
  URL?: string;
  isDivider?: boolean;
  logo: string;
}

export interface ICustomFormQuestion {
  _id?: string;
  field:
    | "Short text"
    | "Email"
    | "URL"
    | "Number"
    | "Long Text"
    | "Dropdown"
    | "CheckBox"
    | "Country select";
  question: string;
  helpText: string;
  isRequire: boolean;
  isDisplay?: boolean;
  multiple?: boolean;
  dropdownOptions?: Array<{ value: string }>;
}

export interface IJudges {
  _id: string;
  name: string;
  email?: string;
  title?: string;
  photo: string;
  userId?: string;
  status?: string;
}
export interface IJudgeCriteria {
  _id: string;
  title: string;
  description: string;
  scores?: string;
}
export interface IAward {
  _id: string;
  name: string;
  value: number;
  count: number;
  description: string;
  isSponsorPrizer: boolean;
  name_alias: string;
  prizeCurrency?:
    | "$"
    | "£"
    | "€"
    | "₹"
    | "$CAD"
    | "MEX$"
    | "nativeToken"
    | "token";
  network?: string;
  token_address?: string;
  symbol?: string;
  projects?: {
    _id: string;
    name: string;
  }[];
  submissionIds?: {
    _id: {
      _id: string;
      projectId?: string;
      transactionId?: string;
      walletAddress?: string;
    };
  }[];
}

export interface IUpdateSiteParams {
  mainDescription: string;
  target_candidate: string;
  hackathon_intro: string;
  video: string;
  rules: string;
  resource: string;
  submissionRequirement: string;
  sponsors: ISponsor[];
}

export interface ITodo {
  _id?: string;
  action?: string;
  label?: string;
  URL?: string;
}
export interface IUpdateTodosParams {
  communityChat: string;
  additionalActions: ITodo[];
}

export interface IUpdateStartKitParams {
  subject: string;
  content: string;
  sendMail: boolean;
}

export interface IUpdateSubmissionParams {
  startDate: any;
  deadline: any;
  custom: {
    finalReminder: string;
    reminderTemplate: string;
    reminderSchedules: number[];
    reminderSpecificDay: number;
    fileUpload: {
      active: boolean;
      require: boolean;
    };
    requireVideos: string;
  };
}

export interface IUpdatePublicVotingParams {
  startVote?: any;
  endVote?: any;
}

export interface IUpdatePrizeParams {
  onDate: any;
  prizeCurrency: string;
  awards: string[];
}

export interface ISchedule {
  period: string;
  begin_date: number;
  end_date: number;
  _id?: string;
}

export interface IHackathonResponse {
  _id: string;
  hackathonOwner: string[];
  participants: string[];
  createdAt: Date;
  updatedAt: Date;
  publish_date: Date;
  flag_publish: boolean;
  preview_token: string;
  status: "draft" | "review" | "rejected" | "pending" | "publish";
  status_inprogess?: "draft" | "submit" | "pending";
  design: IUpdateDesignParams;
  site: IUpdateSiteParams;
  essentials: IUpdateEssentialParams;
  eligibility: IUpdateEligibilityParams;
  todo: IUpdateTodosParams;
  startKit: IUpdateStartKitParams;
  vote: IUpdatePublicVotingParams;
  start_judging_period: boolean;
  submit_late: boolean;
  gallery: boolean;
  topics: IHackathonTopic[];
  subscribeDiscussions?: string[];
  total_submission?: number;
  is_hide?: boolean;
  prizes: {
    onDate: any;
    prizeCurrency?: "$" | "£" | "€" | "₹" | "$CAD" | "MEX$" | "ETH";
    awards: IAward[];
    total_prizes?: any;
    network?: "Ethereum" | "Aurora" | "Polygon" | string;
  };
  judging: {
    judgingType: "online" | "offline";
    judgingDate: {
      startDate: any;
      endDate: any;
      endJudging: boolean;
    };
    judges?: IJudges[];
    criteria: IJudgeCriteria[];
  };
  submission: {
    startDate: any;
    deadline: any;
    custom: {
      finalReminder: string;
      reminderTemplate: string;
      reminderSchedules: number[];
      reminderSpecificDay: number;
      fileUpload: {
        active: boolean;
        require: boolean;
      };
      requireVideos?: "on" | "off";
    };
    customQuestion: ICustomFormQuestion[];
    customFormQuestion: ICustomFormQuestion[];
  };
  contract?: {
    0?: string;
    1?: string;
    2?: string;
    3?: string;
    4?: string;
    withdrawTime: number;
    closedAt?: string;
    id?: string;
    owner?: string;
    totalPrizeCountLeft?: string;
    url?: string;
    isWithdraw?: boolean;
  };
  schedules: ISchedule[];
}

export interface IHackathonListResponse {
  pagedResult: {
    items: IHackathonResponse[];
    page: number;
    pageSize: number;
    totalItems: number;
  };
}
export interface IListHackathonPayload {
  search: string;
  eligibility: boolean;
  lengths: string[];
  themeTags: string[];
  host?: string;
  status: string[]; // Upcoming | ended | open
  order_by: "relevant" | "submission" | "added" | "prize";
  page: number;
  pageSize: number;
  isHide?: boolean;
}

export interface IParticipantTeamFormCounter {
  value: string;
  count: number;
}
export interface IListParticipantParams {
  search: string;
  skills: string[];
  specialty: string[];
  sortBy: "Project" | "Follower" | "Newest";
  teamForm: string;
}
export interface IParticipant {
  participants: IUserData[];
  skills: IParticipantTeamFormCounter[];
  specialties: IParticipantTeamFormCounter[];
  teams: IParticipantTeamFormCounter[];
}
export interface ICreateTopicParams {
  hackathonId: string;
  topic_type: "hackathon";
  title: string;
  message: string;
}
export interface IUpdateTopicParams {
  _id: string;
  title: string;
  message: string;
}
export interface IHackathonTopic {
  comments: IComment[];
  _id: string;
  hackathonId: string;
  topic_type: "hackathon";
  title: string;
  message: string;
  userId: IUserData;
  createdAt: Date;
  updatedAt: Date;
  flags: any[];
}

export interface ICustomQuestionParams {
  _id: string;
  content?: string;
  option?: string[];
  isChoose?: boolean;
  select_option?: string;
}
export interface IJoinHackathonParams {
  hackathonId: string;
  userId: string;
  teammates: string;
  introduce?: string;
  recommend?: string;
  via?: string;
  submission_participant: {
    CustomQuestion: ICustomQuestionParams[];
  };
}

export interface IChangeTeamUpStatusParams {
  status: string;
  introduce?: string;
  hackathonId?: string;
}

export interface ITodoStatus {
  _id?: string;
  isChecked: boolean;
  isDismiss: boolean;
}
export interface ITodoStatusResponse {
  todo: ITodoStatus[];
  _id: string;
}

export interface IHackathonUpdate {
  _id: string;
  content: string;
  title: string;
  immediately: boolean;
  hackathonOwner: string;
  status: "Schedule" | "Published";
  type: "Normal" | "AutoSubmission" | "AutoAnnounceWinner" | "AutoVoting" | "";
  isSendBySchedule: boolean;
  createdAt: Date;
  updatedAt: Date;
  specific_date: Date;
  hackathonId: {
    _id: string;
    timeZone: string;
    mailManage: string;
  };
}
export interface ICreateHackathonUpdate {
  hackathonId: string;
  title: string;
  content: string;
  immediately: boolean;
  specific_date?: number;
}
export interface IUpdateHackathonUpdate {
  hackathon_update_id: string;
  title: string;
  content: string;
  immediately: boolean;
  specific_date: number;
  isSendBySchedule?: boolean;
}

export interface IJudgingSubmission {
  votes: any[];
  rating?: number;
  status: "not_started" | "in_progess" | "judged";
  _id: string;
  projectId: {
    _id: string;
    name: string;
  };
}
export interface IMarkSubmissionPayload {
  hackathonId: string;
  submissionId: string;
  judgeId: string;
  mark: { [key: string]: string }[];
  isRecuse: boolean;
}

export interface IListHackathonProjectGalleryPayload {
  search: string;
  sortBy: string;
  page: number;
  pageSize: number;
  childTag: string[];
  filter_categories?: any;
}
export interface HackathonProjectGallery {
  _id: string;
  projectOwner: IUserData;
  projectId: IProject;
}
export interface IListHackathonProjectGallery {
  categories: {
    _id: string;
    name: string;
    totals: number;
  }[];
  parentAndChildTags: IMasterTag;
  projects: {
    pagedResult: {
      items: HackathonProjectGallery[];
      page: number;
      pageSize: number;
      totalItems: number;
    };
  };
}

export interface IFlagDiscussion {
  _id: string;
  topicId?: string;
  flags: {
    commentId?: string;
    topicId?: string;
    reason: string;
    userId: {
      _id: string;
      firstName: string;
      lastName: string;
      userName: string;
    }[];
    _id: string;
    createdAt: string;
  }[];
  title: string;
}
export interface ISupportedChain {
  _id: string;
  name: string;
  __v: number;
}

export interface IInfraDeploy {
  _id: string;
  name: string;
  __v: number;
}
