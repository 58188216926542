import { FC } from "react";

const Eye: FC<{ color?: string; className?: string }> = ({
  color = "black",
  className,
}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.18115 12C2.12115 6.88 6.60815 3 12.0002 3C17.3922 3 21.8782 6.88 22.8192 12C21.8792 17.12 17.3922 21 12.0002 21C6.60815 21 2.12215 17.12 1.18115 12ZM12.0002 17C13.3262 17 14.598 16.4732 15.5357 15.5355C16.4734 14.5979 17.0002 13.3261 17.0002 12C17.0002 10.6739 16.4734 9.40215 15.5357 8.46447C14.598 7.52678 13.3262 7 12.0002 7C10.6741 7 9.4023 7.52678 8.46462 8.46447C7.52694 9.40215 7.00015 10.6739 7.00015 12C7.00015 13.3261 7.52694 14.5979 8.46462 15.5355C9.4023 16.4732 10.6741 17 12.0002 17ZM12.0002 15C11.2045 15 10.4414 14.6839 9.87883 14.1213C9.31622 13.5587 9.00015 12.7956 9.00015 12C9.00015 11.2044 9.31622 10.4413 9.87883 9.87868C10.4414 9.31607 11.2045 9 12.0002 9C12.7958 9 13.5589 9.31607 14.1215 9.87868C14.6841 10.4413 15.0002 11.2044 15.0002 12C15.0002 12.7956 14.6841 13.5587 14.1215 14.1213C13.5589 14.6839 12.7958 15 12.0002 15Z"
        fill={color}
      />
    </svg>
  );
};

export default Eye;
