import { message } from "antd";
import { getHackathonId } from "api/auth";
import { useQueryParams } from "hooks";
import { routesEnum } from "pages/Routes";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { domain, getHost, getSubDomain } from "utils/domain";

export const useDomainCheck = () => {
  const history = useHistory();
  const error = useQueryParams().get("access_denied");
  useEffect(() => {
    (async () => {
      const host = getHost();
      if (!location.pathname.includes("challenges/") && host !== domain) {
        try {
          const subDomain = getSubDomain();

          if (subDomain === "www") {
            return history.push(routesEnum.home);
          }

          const { _id } = await getHackathonId(subDomain);
          history.push(`${routesEnum.hackathon}/overview/${_id}`);
        } catch (err) {
          const _404 = window.location.protocol + "//" + domain + "/404";
          window.location.assign(_404);
        }
      }
      if (error) {
        if (error === "judge") {
          message.warning(
            "Access denied !. Please use the correct account to judge this hackathon"
          );
        }
        if (error === "host") {
          message.warning(
            "Access denied !. Only hackathon owner can access this page"
          );
        }
        if (error === "hackathon_not_started") {
          message.warning("Hackathon not started");
        }
      }
    })();
  }, []);
};
