import clsx from "clsx";
import { CSSProperties, FC } from "react";
import styles from "./styles.module.css";
import image from "assets/svgs/Logo.svg";

interface LogoProps {
  style?: CSSProperties;
  className?: string;
  theme?: "light" | "dark";
}

export const Logo: FC<LogoProps> = ({ className, style, theme = "light" }) => {
  return (
    <div className={clsx(styles.logo, styles[theme], className)} style={style}>
      <img src={image} alt="logo" />
    </div>
  );
};
