import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFilterSubmission } from "api/Dashboard";
import { RootState } from "store";

const initialState = {
  search: "",
  unread_message: false,
  visibility: ["draft", "pending", "hidden", "gallery"],
  judge: ["pending", "judge", "maybe", "do_not_judge"],
} as IFilterSubmission;

const filterSubmissionSlice = createSlice({
  name: "filterSubmission",
  initialState,
  reducers: {
    setVisibility(state, action: PayloadAction<string[]>) {
      if (action.payload.length === 0) {
        delete state.visibility;
        return state;
      }
      return {
        ...state,
        visibility: action.payload,
      };
    },
    setJudge(state, action: PayloadAction<string[]>) {
      if (action.payload.length === 0) {
        delete state.judge;
        return state;
      }
      return {
        ...state,
        judge: action.payload,
      };
    },
    setSearch(state, action: PayloadAction<string>) {
      return {
        ...state,
        search: action.payload,
      };
    },
    setUnreadMsg(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        unread_message: action.payload,
      };
    },
    clearSubmissionFilter(state) {
      return {
        ...state,
        ...initialState,
      };
    },
    resetSubmissionFilter(state) {
      return {
        ...state,
        visibility: ["draft", "pending", "hidden", "gallery"],
        judge: ["pending", "judge", "maybe", "do_not_judge"],
        unread_message: false,
      };
    },
  },
});

export const getFilterSubmission = (state: RootState): IFilterSubmission => {
  return state.filterSubmission;
};

export const getSearchSubmission = (state: RootState): string => {
  return state.filterSubmission.search || "";
};

export const {
  clearSubmissionFilter,
  resetSubmissionFilter,
  setVisibility,
  setJudge,
  setSearch,
  setUnreadMsg,
} = filterSubmissionSlice.actions;
export default filterSubmissionSlice.reducer;
