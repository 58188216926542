import { FC } from "react";
import { Button } from "antd";
import styles from "./styles.module.css";

interface IProps {
  error: any;
  resetErrorBoundary: any;
}

export const ErrorBoundary: FC<IProps> = () => {
  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <div className={styles["error-boundary-comp-wrapper"]}>
      <div className={styles["error-boundary-container"]}>
        <div className={styles["title"]}>
          <strong>Page Load Error</strong>
        </div>

        <Button
          type="primary"
          onClick={onRefresh}
          className={styles["refresh-button"]}
        >
          Reload
        </Button>

        <div className={styles["error-info"]}>
          <pre>
            <code>500</code>
          </pre>
        </div>
      </div>
    </div>
  );
};
