import { FC } from "react";

const SortIcon: FC = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 21H10.5V18.6667H3.5V21ZM3.5 7V9.33333H24.5V7H3.5ZM3.5 15.1667H17.5V12.8333H3.5V15.1667Z"
        fill="black"
      />
    </svg>
  );
};

export default SortIcon;
