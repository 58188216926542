import { FC } from "react";

const Tag: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.00005L11.445 1.37005C11.6093 1.26042 11.8025 1.2019 12 1.2019C12.1975 1.2019 12.3907 1.26042 12.555 1.37005L21 7.00005V21.0001C21 21.2653 20.8946 21.5196 20.7071 21.7072C20.5196 21.8947 20.2652 22.0001 20 22.0001H4C3.73478 22.0001 3.48043 21.8947 3.29289 21.7072C3.10536 21.5196 3 21.2653 3 21.0001V7.00005ZM12 11.0001C12.5304 11.0001 13.0391 10.7893 13.4142 10.4143C13.7893 10.0392 14 9.53049 14 9.00005C14 8.46962 13.7893 7.96091 13.4142 7.58584C13.0391 7.21077 12.5304 7.00005 12 7.00005C11.4696 7.00005 10.9609 7.21077 10.5858 7.58584C10.2107 7.96091 10 8.46962 10 9.00005C10 9.53049 10.2107 10.0392 10.5858 10.4143C10.9609 10.7893 11.4696 11.0001 12 11.0001ZM8 16.0001V18.0001H16V16.0001H8ZM8 13.0001V15.0001H16V13.0001H8Z"
        fill="black"
      />
    </svg>
  );
};

export default Tag;
