import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

export const INFURA_ID = process.env.REACT_APP_INFURA_ID!;
export const INFURA_ENDPOINT = process.env.REACT_APP_INFURA_ENDPOINT!;
export const CONTRACT_ADDRESS = process.env.REACT_APP_SC_ADDRESS!;
export const CONTRACT_NETWORK = process.env.REACT_APP_SC_NETWORK!;

export const NETWORK_CHAIN_IDS = {
  mainnet: 1,
  rinkeby: 4,
  ropsten: 3,
  polygon: 137,
  mumbai: 80001,
  aurora: 1313161554,
  auroraTest: 1313161555,
};

export const CONTRACT_ADDRESSES: Record<number, string> = {
  [NETWORK_CHAIN_IDS.mainnet]: process.env.REACT_APP_ETH_HACKATHON_SC!,
  [NETWORK_CHAIN_IDS.rinkeby]: process.env.REACT_APP_ETH_HACKATHON_SC!,
  [NETWORK_CHAIN_IDS.ropsten]: process.env.REACT_APP_ETH_HACKATHON_SC!,
  [NETWORK_CHAIN_IDS.polygon]: process.env.REACT_APP_POLYGON_HACKATHON_SC!,
  [NETWORK_CHAIN_IDS.mumbai]: process.env.REACT_APP_POLYGON_HACKATHON_SC!,
  [NETWORK_CHAIN_IDS.aurora]: process.env.REACT_APP_AURORA_HACKATHON_SC!,
  [NETWORK_CHAIN_IDS.auroraTest]: process.env.REACT_APP_AURORA_HACKATHON_SC!,
};

export const GAS_DEFAULT = 0.001; //ETH
export const NATIVE_TOKEN_ADDRESS =
  "0x0000000000000000000000000000000000000000";

const supportedChainIds: number[] = [
  NETWORK_CHAIN_IDS.rinkeby,
  NETWORK_CHAIN_IDS.mainnet,
  NETWORK_CHAIN_IDS.mumbai,
  NETWORK_CHAIN_IDS.polygon,
  NETWORK_CHAIN_IDS.aurora,
  NETWORK_CHAIN_IDS.auroraTest,
];

export const injected = new InjectedConnector({
  supportedChainIds: supportedChainIds,
});

export const walletconnect = new WalletConnectConnector({
  infuraId: INFURA_ID,
  supportedChainIds: supportedChainIds,
  rpc: { 1: INFURA_ENDPOINT },
  qrcode: true,
});

export const walletlink = new WalletLinkConnector({
  url: INFURA_ENDPOINT,
  appName: "CrowdHack Rebaked",
  supportedChainIds: supportedChainIds,
});
