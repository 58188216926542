import { useCallback, useEffect, useMemo, useState } from "react";
import { CONTRACT_ADDRESS, injected } from "utils/walletUtils";
import HackathonRebakedABI from "contracts/HackathonRebaked.json";
import ERC20ABI from "contracts/ERC20.json";
import { useContract, useContractNoSigner } from "./useContract";
import { ERC20, HackathonRebaked } from "contracts/types";
import { Contract } from "@ethersproject/contracts";
import { message } from "antd";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { formatEther } from "@ethersproject/units";

export const useEagerConnect = () => {
  const { activate, active } = useWeb3React<Web3Provider>();

  const [tried, setTried] = useState(false);

  useEffect(() => {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true).catch(() => {
          setTried(true);
        });
      } else {
        setTried(true);
      }
    });
  }, []);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
};

export const useInactiveListener = (suppress: boolean = false) => {
  const { active, error, activate, deactivate } = useWeb3React<Web3Provider>();

  useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected, (error: Error) => {
          message.error(error.message);
          deactivate();
        });
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'networkChanged' event with payload", networkId);
        activate(injected);
      };

      ethereum.on("connect", handleConnect);
      ethereum.on("chainChanged", handleChainChanged);
      ethereum.on("accountsChanged", handleAccountsChanged);
      ethereum.on("networkChanged", handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener("connect", handleConnect);
          ethereum.removeListener("chainChanged", handleChainChanged);
          ethereum.removeListener("accountsChanged", handleAccountsChanged);
          ethereum.removeListener("networkChanged", handleNetworkChanged);
        }
      };
    }
  }, [active, error, suppress, activate]);
};

export const useWalletInfo = () => {
  const { account, library, chainId } = useWeb3React<Web3Provider>();
  const [balance, setBalance] = useState<any>();

  useEffect(() => {
    if (!!account && !!library) {
      let stale = false;
      (async () => {
        try {
          const balanceWei = await library.getBalance(account);
          const balanceEth = formatEther(balanceWei ?? "0");
          if (!stale) setBalance(Number(balanceEth).toFixed(4) || "0");
        } catch (err) {
          if (!stale) setBalance(undefined);
        }
      })();
      return () => {
        stale = true;
        setBalance(undefined);
      };
    }
  }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

  const truncatedAccount = useMemo(() => {
    return account === null
      ? "-"
      : account
      ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
      : "";
  }, [account]);

  return { balance, truncatedAccount, account, chainId };
};

export const useHackathonContract = (tokenAddress = CONTRACT_ADDRESS) => {
  return useContract<HackathonRebaked>(tokenAddress, HackathonRebakedABI);
};

export const useHackathonContractNoSigner = (
  tokenAddress = CONTRACT_ADDRESS
) => {
  return useContractNoSigner<HackathonRebaked>(
    tokenAddress,
    HackathonRebakedABI
  );
};

export const useSymbolToken = () => {
  const { library, account, chainId, connector } = useWeb3React<Web3Provider>();

  const getSymbol = useCallback(
    async (tokenAddress: string) => {
      if (library && account) {
        const contract = new Contract(
          tokenAddress,
          ERC20ABI,
          library.getSigner(account)
        ) as ERC20;
        return await contract.symbol();
      } else {
        message.error("an error occurred while fetch token data.");
      }
    },
    [library]
  );
  return { getSymbol, isConnect: !!account, chainId, connector };
};

export const useTokenContract = (library: Web3Provider, account: string) => {
  const getTokenContract = useCallback(
    async (tokenAddress: string) => {
      return new Contract(
        tokenAddress,
        ERC20ABI,
        library.getSigner(account)
      ) as ERC20;
    },
    [library]
  );
  return { getTokenContract };
};
