import { FC } from "react";

const Public: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9996 12C10.6735 12 9.40176 11.4732 8.46408 10.5355C7.52639 9.59785 6.99961 8.32608 6.99961 7C6.99961 5.67392 7.52639 4.40215 8.46408 3.46447C9.40176 2.52678 10.6735 2 11.9996 2C13.3257 2 14.5975 2.52678 15.5351 3.46447C16.4728 4.40215 16.9996 5.67392 16.9996 7C16.9996 8.32608 16.4728 9.59785 15.5351 10.5355C14.5975 11.4732 13.3257 12 11.9996 12V12ZM17.9996 17V22H15.9996V17C15.9996 12.549 18.6436 8.715 22.4466 6.984L23.2746 8.804C21.7013 9.51958 20.3673 10.6729 19.4319 12.1263C18.4965 13.5797 17.9993 15.2716 17.9996 17V17ZM7.99961 17V22H5.99961V17C5.99976 15.2718 5.50244 13.5801 4.56705 12.1269C3.63166 10.6737 2.29774 9.52051 0.724609 8.805L1.55261 6.984C3.4753 7.85849 5.10561 9.26796 6.24883 11.0441C7.39205 12.8201 7.99984 14.8878 7.99961 17Z"
        fill="black"
      />
    </svg>
  );
};

export default Public;
