import { FC } from "react";

const ComingSoonTwoTone: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6808 7.07062L9.50891 7.07007L9.50684 11.7578H14.1964V10.586H10.6792L10.6808 7.07062Z"
        fill="#FF9900"
      />
      <path
        d="M14.929 5.28957L15.9522 3.8284L14.9922 3.15625L13.9698 4.61645C12.9921 4.03637 11.8747 3.66824 10.6808 3.57699V2.38281H11.8527V1.21094H10.6808H9.50895H8.33707V2.38281H9.50895V3.57699C8.31508 3.66824 7.19762 4.03637 6.22 4.61645L5.19758 3.15625L4.23766 3.8284L5.26078 5.28957C4.30719 6.07465 3.54727 7.08672 3.06398 8.24219L2.68281 9.41406C2.59258 9.79457 2.53059 10.1859 2.50004 10.5859L2.5 11.7578C2.53059 12.1578 2.59254 12.5492 2.68277 12.9297L3.06428 14.1016C4.21451 16.8517 6.93238 18.7891 10.0949 18.7891C14.295 18.7891 17.7121 15.372 17.7121 11.1719C17.7121 8.80547 16.6273 6.68773 14.929 5.28957ZM10.0949 17.6172C7.5952 17.6172 5.42332 16.1868 4.35473 14.1016H5.99332V12.9297H3.89324C3.78602 12.552 3.71293 12.1601 3.67648 11.7578H4.82145V10.5859H3.67648C3.71293 10.1836 3.78602 9.7918 3.89324 9.41406H5.99332V8.24219H4.35473C5.42332 6.15695 7.5952 4.72656 10.0949 4.72656C13.6488 4.72656 16.5402 7.61793 16.5402 11.1719C16.5402 14.7258 13.6488 17.6172 10.0949 17.6172Z"
        fill="#FF9900"
      />
    </svg>
  );
};

export default ComingSoonTwoTone;
