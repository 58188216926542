import { FC, memo, useEffect, useState } from "react";
import styles from "./Notification.module.css";
import { Button, Popover, Badge } from "antd";
import { Bell } from "assets/svgs";

import ListNotification from "./ListNotification";
import {
  markReadNotificationRequest,
  useCheckNotification,
} from "api/notification";
import { useMutation, useQueryClient } from "react-query";

const NotificationButton: FC = () => {
  const [open, setOpen] = useState(false);
  const { data: isReadAll } = useCheckNotification();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(markReadNotificationRequest, {
    onSuccess: () => {
      queryClient.setQueriesData("checkNotification", true);
    },
  });

  useEffect(() => {
    if (open && !isReadAll) {
      mutate();
    }
  }, [open, isReadAll]);

  return (
    <div className="h-100 f-center">
      <Popover
        content={open ? <ListNotification /> : null}
        // trigger="click"
        placement="bottom"
        overlayInnerStyle={{ background: "transparent", boxShadow: "unset" }}
        onVisibleChange={(visible) => setOpen(visible)}
      >
        <Badge color="#FFCE07" dot={!isReadAll} offset={[-10, 10]}>
          <Button
            type={open ? "default" : "text"}
            className={styles.btnBell}
            icon={<Bell active={open} />}
          />
        </Badge>
      </Popover>
    </div>
  );
};

export default memo(NotificationButton);
