import { FC } from "react";

const Close: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="18" cy="18" r="17.5" stroke="#080A25" strokeOpacity="0.6" />
      <path
        d="M25.2008 10.8L10.8008 25.2"
        stroke="#080A25"
        strokeOpacity="0.6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8008 10.8L25.2008 25.2"
        stroke="#080A25"
        strokeOpacity="0.6"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Close;
