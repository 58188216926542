export const domain = process.env.REACT_APP_DOMAIN_NAME;
export const BLOG_URL = `https://${domain}/blog`;

export const getSubDomain = () => {
  const [cname, subdomain] = window.location.host.split(".");
  return cname === "www" ? subdomain : cname;
};

export const getHackathonDomain = (subDomain: string = "") => {
  // let prefix = "";
  // if (window.location.host.indexOf("www.") !== -1) {
  //   prefix += "www.";
  // }
  // prefix += subDomain ? subDomain + "." : "";
  // return location.protocol + "//" + prefix + getHost();
  return location.protocol + "//" + domain + "/" + subDomain;
};

export const getHost = () => {
  return window.location.host.replace(/^www\./, "");
};
