import { FC } from "react";

const Gift: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3337 8V14.6667H2.66699V8"
        stroke="#080A25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6663 4.66699H1.33301V8.00033H14.6663V4.66699Z"
        stroke="#080A25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.667V4.66699"
        stroke="#080A25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99967 4.66634H4.99967C4.55765 4.66634 4.13372 4.49075 3.82116 4.17819C3.5086 3.86562 3.33301 3.4417 3.33301 2.99967C3.33301 2.55765 3.5086 2.13372 3.82116 1.82116C4.13372 1.5086 4.55765 1.33301 4.99967 1.33301C7.33301 1.33301 7.99967 4.66634 7.99967 4.66634Z"
        stroke="#080A25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.66634H11C11.442 4.66634 11.866 4.49075 12.1785 4.17819C12.4911 3.86562 12.6667 3.4417 12.6667 2.99967C12.6667 2.55765 12.4911 2.13372 12.1785 1.82116C11.866 1.5086 11.442 1.33301 11 1.33301C8.66667 1.33301 8 4.66634 8 4.66634Z"
        stroke="#080A25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Gift;
