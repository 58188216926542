import { FC } from "react";

const Share: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33203 10V16.6667C3.33203 17.1087 3.50763 17.5326 3.82019 17.8452C4.13275 18.1577 4.55667 18.3333 4.9987 18.3333H14.9987C15.4407 18.3333 15.8646 18.1577 16.1772 17.8452C16.4898 17.5326 16.6654 17.1087 16.6654 16.6667V10"
        stroke="#102548"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3346 4.99984L10.0013 1.6665L6.66797 4.99984"
        stroke="#102548"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.66663V12.5"
        stroke="#102548"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Share;
