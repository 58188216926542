import { FC } from "react";

const ArrowRight: FC = () => {
  return (
    <svg
      width="28"
      height="16"
      viewBox="0 0 28 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.8149 7.54839L21.4515 1.18485C21.2229 0.917923 20.8211 0.886806 20.5542 1.11545C20.2873 1.34404 20.2562 1.74577 20.4848 2.01269C20.5061 2.03757 20.5293 2.06082 20.5542 2.08209L25.8295 7.36383H0.636333C0.284917 7.36383 0 7.64874 0 8.00021C0 8.35168 0.284917 8.63655 0.636333 8.63655H25.8295L20.5542 13.9119C20.2873 14.1405 20.2562 14.5422 20.4848 14.8091C20.7135 15.0761 21.1151 15.1072 21.3821 14.8785C21.4069 14.8572 21.4302 14.834 21.4515 14.8091L27.815 8.44558C28.0617 8.19747 28.0617 7.79661 27.8149 7.54839Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowRight;
