import { FC, memo } from "react";
import styles from "./styles.module.css";

import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  closeWalletModal,
  getWalletModalState,
} from "store/ducks/wallet-modal/slice";

import WalletConnectIcon from "assets/images/walletConnectIcon.svg";
import CoinbaseWalletIcon from "assets/images/coinbaseWalletIcon.svg";
import MetaMask from "assets/images/metamask.png";
import { useWallet } from "./useWallet";

const WalletModal: FC = () => {
  const dispatch = useAppDispatch();
  const { connect } = useWallet();
  const open = useAppSelector(getWalletModalState);

  const handleConnect = async (
    key: "injected" | "walletconnect" | "walletlink"
  ) => {
    try {
      await connect(key);
      toggleOpen();
    } catch (err) {
      console.log({ err });
    }
  };

  const toggleOpen = () => {
    dispatch(closeWalletModal());
  };

  return (
    <Modal
      className={styles.root}
      visible={open}
      title={<b>Connect a wallet</b>}
      footer={null}
      centered
      width={418}
      onCancel={toggleOpen}
    >
      <ul className={styles.menu}>
        <li onClick={() => handleConnect("injected")}>
          <p>MetaMask</p> <img src={MetaMask} />
        </li>
        <li onClick={() => handleConnect("walletconnect")}>
          <p>WalletConnect</p> <img src={WalletConnectIcon} />
        </li>
        <li onClick={() => handleConnect("walletlink")}>
          <p>Coinbase Wallet</p> <img src={CoinbaseWalletIcon} />
        </li>
      </ul>
    </Modal>
  );
};

export default memo(WalletModal);
