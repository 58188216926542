import { FC, memo, useMemo, useRef } from "react";
import styles from "./Notification.module.css";
import { List, Button, Spin } from "antd";

import { useInfinityListNotification } from "api/notification/queries";
import { LIST_NOTIFICATION_PAGE_SIZE } from "api/notification";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import { Hidden } from "components/Hidden";
import NotificationItem from "./NotificationItem";

const ListNotification: FC = () => {
  const ref = useRef<any>(null);
  const { data, isLoading, hasNextPage, fetchNextPage } =
    useInfinityListNotification({
      refetchOnMount: true,
      getNextPageParam: (page) => {
        const pagedResult = page?.pagedResult;
        const currentPage = Number(pagedResult?.page || 0);
        const totalItems = Number(pagedResult?.totalItems || 0);
        const totalPage = Math.ceil(
          pagedResult?.totalItems / LIST_NOTIFICATION_PAGE_SIZE
        );
        const isLastPage = currentPage === totalPage;
        if (totalItems === 0) return undefined;
        return isLastPage ? undefined : currentPage + 1;
      },
    });

  useIntersectionObserver({
    target: ref,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  });

  const total = useMemo(() => {
    return data?.pages[data.pages.length - 1]?.pagedResult?.totalItems || 0;
  }, [data?.pages]);

  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <h3 className="center m-0">Notification</h3>
      </div>
      {total === 0 && (
        <div className="center mt-32 mb-32">
          <img src="/images/icon/alarm.png" alt="empty" />
          <h2 className="m-0 mr-8 poppins text-24">
            Nothing to see here (yet)!
          </h2>
          <div className="text-18 regular">
            When someone likes your projects, you&apos;ll see <br />
            it here.
          </div>
        </div>
      )}

      <div className={styles.scroll}>
        {data?.pages?.map((page) =>
          page.pagedResult.items?.map((item) => (
            <NotificationItem key={item._id} data={item} />
          ))
        )}
      </div>

      {isLoading && <Spin />}
      <Hidden js cond={hasNextPage}>
        <div className="center" ref={ref}>
          <Button className="info" type="text">
            Load more
          </Button>
        </div>
      </Hidden>
    </div>
  );
};

export default memo(ListNotification);
