import { FC } from "react";

const EyeOutline: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.833496 9.99992C0.833496 9.99992 4.16683 3.33325 10.0002 3.33325C15.8335 3.33325 19.1668 9.99992 19.1668 9.99992C19.1668 9.99992 15.8335 16.6666 10.0002 16.6666C4.16683 16.6666 0.833496 9.99992 0.833496 9.99992Z"
        stroke="#080A25"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
        stroke="#080A25"
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeOutline;
