import { FC } from "react";
import styles from "./Avatar.module.css";

import { Avatar as AntdAvatar, AvatarProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";

type CustomAvatarProps = Partial<AvatarProps> & {
  border?: "small" | "middle" | "large" | boolean;
  gradient?: boolean;
};

export const Avatar: FC<CustomAvatarProps> = ({
  border = "small",
  className,
  icon,
  children,
  gradient,
  ...props
}) => {
  return (
    <AntdAvatar
      className={clsx(
        styles.root,
        gradient && styles.gradient,
        styles[String(border)],
        className
      )}
      icon={
        icon || <FontAwesomeIcon color="rgba(16, 37, 72, 0.4)" icon={faUser} />
      }
      {...props}
    >
      {children}
    </AntdAvatar>
  );
};

export const AvatarGroup = AntdAvatar.Group;
