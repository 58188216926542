import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useUser } from "api/auth";
import { routesEnum } from "pages/Routes";
import { LoadingFullpage } from "components/Loading";

const PrivateRoute: React.FC<RouteProps> = ({ path, exact, component }) => {
  const { data: userData, isLoading } = useUser();

  if (isLoading) return <LoadingFullpage />;

  return userData ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to={routesEnum.signIn} />
  );
};

export default PrivateRoute;
