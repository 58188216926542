import { FC } from "react";

const UserGroup: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 11C6.40905 11 5.82389 10.8836 5.27792 10.6575C4.73196 10.4313 4.23588 10.0998 3.81802 9.68198C3.40016 9.26412 3.06869 8.76804 2.84254 8.22208C2.6164 7.67611 2.5 7.09095 2.5 6.5C2.5 5.90905 2.6164 5.32389 2.84254 4.77792C3.06869 4.23196 3.40016 3.73588 3.81802 3.31802C4.23588 2.90016 4.73196 2.56869 5.27792 2.34254C5.82389 2.1164 6.40905 2 7 2C8.19347 2 9.33807 2.47411 10.182 3.31802C11.0259 4.16193 11.5 5.30653 11.5 6.5C11.5 7.69347 11.0259 8.83807 10.182 9.68198C9.33807 10.5259 8.19347 11 7 11V11ZM17.5 15C16.4391 15 15.4217 14.5786 14.6716 13.8284C13.9214 13.0783 13.5 12.0609 13.5 11C13.5 9.93913 13.9214 8.92172 14.6716 8.17157C15.4217 7.42143 16.4391 7 17.5 7C18.5609 7 19.5783 7.42143 20.3284 8.17157C21.0786 8.92172 21.5 9.93913 21.5 11C21.5 12.0609 21.0786 13.0783 20.3284 13.8284C19.5783 14.5786 18.5609 15 17.5 15ZM17.5 16C18.6935 16 19.8381 16.4741 20.682 17.318C21.5259 18.1619 22 19.3065 22 20.5V21H13V20.5C13 19.3065 13.4741 18.1619 14.318 17.318C15.1619 16.4741 16.3065 16 17.5 16V16ZM7 12C7.65661 12 8.30679 12.1293 8.91342 12.3806C9.52004 12.6319 10.0712 13.0002 10.5355 13.4645C10.9998 13.9288 11.3681 14.48 11.6194 15.0866C11.8707 15.6932 12 16.3434 12 17V21H2V17C2 15.6739 2.52678 14.4021 3.46447 13.4645C4.40215 12.5268 5.67392 12 7 12V12Z"
        fill="black"
      />
    </svg>
  );
};

export default UserGroup;
