import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export interface ISoftware {
  tags: string[];
}

const initialState = {
  tags: [],
} as ISoftware;

const walletModalSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTags: (state: ISoftware, actions: PayloadAction<string[]>) => {
      return {
        tags: actions.payload,
      };
    },
  },
});

export const getSoftwareTag = (state: RootState) => state.software.tags;

export const { setTags } = walletModalSlice.actions;

export default walletModalSlice.reducer;
