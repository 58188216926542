import { IHackathonResponse } from "api/hackathon";

export interface INotification {
  _id: string;
  avatar?: string;
  thumbnail?: string;
  ownerId: string;
  iconType: "user" | "comment" | "like" | "submit_project";
  message: string;
  isWatched?: boolean;
  userFullName: string;
  projectName: string;
  hackathonName: string;
  projectId?: string;
  username?: string;
  createdAt: string;
  updatedAt: string;
  hackathonId: IHackathonResponse;
  submissionId: string;
  thumbnailHackathon: string;
  isRead: false;
}

export interface IListNotification {
  pagedResult: {
    items: INotification[];
    page: number;
    pageSize: number;
    totalItems: number;
  };
}

export enum ENotificationMessage {
  likeProject = "liked your project",
  following = "is now following you!",
  commented = "commented on your project",
  submit_project = "had new submission",
}

export const LIST_NOTIFICATION_PAGE_SIZE = 10;
