import { FC } from "react";

const Crow: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79989 5.20008L6.99989 8.00008L11.1859 2.14008C11.2784 2.01045 11.4005 1.90479 11.5421 1.83188C11.6837 1.75898 11.8406 1.72095 11.9999 1.72095C12.1591 1.72095 12.3161 1.75898 12.4577 1.83188C12.5993 1.90479 12.7214 2.01045 12.8139 2.14008L16.9999 8.00008L21.1999 5.20008C21.3588 5.09433 21.5447 5.03629 21.7356 5.03282C21.9265 5.02935 22.1144 5.0806 22.2771 5.1805C22.4398 5.28041 22.5705 5.4248 22.6537 5.59662C22.737 5.76843 22.7693 5.96048 22.7469 6.15008L21.1039 20.1171C21.0752 20.3603 20.9583 20.5846 20.7753 20.7474C20.5922 20.9101 20.3558 21 20.1109 21.0001H3.88889C3.64395 21 3.40755 20.9101 3.22451 20.7474C3.04148 20.5846 2.92454 20.3603 2.89589 20.1171L1.25289 6.14908C1.23069 5.95957 1.26317 5.76765 1.34651 5.596C1.42984 5.42435 1.56055 5.28012 1.7232 5.18034C1.88584 5.08057 2.07364 5.02941 2.26442 5.03291C2.45521 5.0364 2.641 5.09441 2.79989 5.20008ZM11.9999 15.0001C12.5303 15.0001 13.039 14.7894 13.4141 14.4143C13.7892 14.0392 13.9999 13.5305 13.9999 13.0001C13.9999 12.4696 13.7892 11.9609 13.4141 11.5859C13.039 11.2108 12.5303 11.0001 11.9999 11.0001C11.4695 11.0001 10.9607 11.2108 10.5857 11.5859C10.2106 11.9609 9.99989 12.4696 9.99989 13.0001C9.99989 13.5305 10.2106 14.0392 10.5857 14.4143C10.9607 14.7894 11.4695 15.0001 11.9999 15.0001Z"
        fill="black"
      />
    </svg>
  );
};

export default Crow;
