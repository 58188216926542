import axios from "api/axios";
import { IListNotification } from "./types";

export const getListNotificationRequest = async (
  page: number
): Promise<IListNotification> => {
  const { data } = await axios({
    url: `/notification?page=${page}`,
    method: "GET",
  });
  return data;
};

export const markReadNotificationRequest = async (): Promise<any> => {
  const { data } = await axios({
    url: `/notification`,
    method: "PUT",
  });
  return data;
};

export const checkNotificationRequest = async (): Promise<any> => {
  const { data } = await axios({
    url: `/notification/is_read_all`,
    method: "GET",
  });
  return data;
};
