import { FC } from "react";

const CompletedTwoTone: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_983_190)">
        <path
          d="M7.00065 0.333008C3.31888 0.333008 0.333984 3.3179 0.333984 6.99967C0.333984 10.6814 3.31888 13.6663 7.00065 13.6663C10.6824 13.6663 13.6673 10.6814 13.6673 6.99967C13.6673 3.3179 10.6824 0.333008 7.00065 0.333008ZM10.8173 5.02233L10.815 5.02467C10.8139 5.02608 10.8124 5.02715 10.8108 5.0278C10.8091 5.02844 10.8073 5.02862 10.8056 5.02832L5.27227 10.5617L3.03529 8.32467C2.89467 8.18412 2.81564 7.99347 2.81559 7.79465C2.81554 7.59583 2.89447 7.40514 3.03503 7.26452C3.17558 7.1239 3.36623 7.04487 3.56505 7.04482C3.76387 7.04477 3.95456 7.12371 4.09518 7.26426L5.27174 8.44082L9.74518 3.9679C9.88539 3.82749 10.0754 3.74819 10.2738 3.74729C10.4723 3.74639 10.663 3.82396 10.8045 3.9631C10.946 4.10223 11.0267 4.29165 11.0291 4.49007C11.0315 4.68848 10.9554 4.87981 10.8173 5.02233Z"
          fill="#00DE8C"
        />
      </g>
      <defs>
        <clipPath id="clip0_983_190">
          <rect
            width="13.3333"
            height="13.3333"
            fill="white"
            transform="translate(0.333984 0.333008)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompletedTwoTone;
