import { FC } from "react";

const Bell: FC<{ color?: string; className?: string }> = ({
  color = "black",
  className,
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.6181 6.468L19.0711 5.015L20.4851 6.429L19.0321 7.882C20.4678 9.67917 21.161 11.9579 20.9691 14.2501C20.7773 16.5424 19.7151 18.6742 18.0005 20.2077C16.286 21.7412 14.0494 22.5601 11.75 22.4961C9.45067 22.4321 7.26307 21.4901 5.63653 19.8635C4.00999 18.237 3.06799 16.0494 3.00398 13.75C2.93997 11.4506 3.75881 9.21402 5.29234 7.49951C6.82587 5.785 8.95767 4.72275 11.2499 4.53092C13.5422 4.33909 15.8209 5.03223 17.6181 6.468ZM11.0001 8.5V14.5H13.0001V8.5H11.0001ZM8.00006 1.5H16.0001V3.5H8.00006V1.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Bell;
