import { FC } from "react";

const ArrowDown: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M12.9997 16.172L18.3637 10.808L19.7777 12.222L11.9997 20L4.22168 12.222L5.63568 10.808L10.9997 16.172V4H12.9997V16.172Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default ArrowDown;
