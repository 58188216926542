import { FC } from "react";

const OngoingTwoTone: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_983_196)">
        <path
          d="M10.7239 2.81903C10.3149 2.81903 9.99561 2.48547 9.99561 2.09078C9.99561 1.66138 10.3695 1.32363 10.7956 1.36617C11.1741 1.40654 11.4521 1.71913 11.4521 2.09078C11.4521 2.48563 11.133 2.81903 10.7239 2.81903V2.81903Z"
          fill="#00ADFB"
        />
        <path
          d="M15.6273 9.90117H15.6277C15.6655 9.50097 16.0209 9.20715 16.4215 9.24498C16.8217 9.28284 17.1155 9.63828 17.0777 10.0388C17.0416 10.4157 16.7244 10.6982 16.3534 10.6982C15.921 10.6983 15.5871 10.3271 15.6273 9.90117V9.90117Z"
          fill="#00ADFB"
        />
        <path
          d="M15.4313 7.54056C15.3271 7.15205 15.5576 6.75296 15.9461 6.64879C16.3347 6.54466 16.7341 6.77516 16.8383 7.1637C16.9424 7.55221 16.7116 7.95166 16.3234 8.05582C16.26 8.07259 16.1967 8.08056 16.1344 8.08056C15.8125 8.08056 15.5183 7.86611 15.4313 7.54056Z"
          fill="#00ADFB"
        />
        <path
          d="M14.4203 5.40199C14.1862 5.07465 14.2616 4.61983 14.5885 4.38572C14.9155 4.15196 15.3703 4.22696 15.6044 4.5543C15.8385 4.88126 15.7631 5.33607 15.4362 5.57019C15.3077 5.66195 15.1598 5.70639 15.0131 5.70639C14.7859 5.70639 14.5623 5.60043 14.4203 5.40199Z"
          fill="#00ADFB"
        />
        <path
          d="M12.525 2.73932C12.7496 2.40576 13.2023 2.318 13.5358 2.54268C13.8693 2.76771 13.9574 3.22035 13.7324 3.55348C13.5919 3.76214 13.3621 3.87464 13.128 3.87464C12.5497 3.87464 12.1976 3.22451 12.525 2.73932V2.73932Z"
          fill="#00ADFB"
        />
        <path
          d="M15.6399 13.228C15.2392 13.228 14.9116 12.902 14.9116 12.4997C14.9116 12.0695 15.2849 11.7328 15.7116 11.7751C16.0968 11.8174 16.3682 12.1329 16.3682 12.4997C16.3682 12.8921 16.05 13.228 15.6399 13.228Z"
          fill="#00ADFB"
        />
        <path
          d="M14.689 13.8893C14.3998 13.6516 13.9776 13.672 13.7125 13.9364C12.5172 15.1284 10.9076 15.8269 9.20489 15.8744C5.54207 15.9767 2.47892 13.0801 2.37661 9.41724C2.30392 6.81549 3.759 4.44348 6.0197 3.29973L6.25372 4.25914C6.30772 4.48063 6.58373 4.55703 6.74395 4.39485L8.97198 2.13951C9.12442 1.98522 9.06078 1.72441 8.85443 1.65764L5.83816 0.681538C5.62127 0.611356 5.41143 0.806245 5.46547 1.02773L5.667 1.85393C2.73758 3.18479 0.828855 6.17102 0.920652 9.4579C1.04568 13.934 4.76905 17.4554 9.24554 17.3304C11.3212 17.2724 13.2832 16.4211 14.7402 14.9684C15.0454 14.6642 15.0219 14.163 14.689 13.8893Z"
          fill="#00ADFB"
        />
        <path
          d="M16.3377 9.24184C16.4081 9.36687 16.4426 9.51388 16.4281 9.66759C16.3924 10.0398 16.0825 10.3196 15.7173 10.3264C15.8413 10.5466 16.077 10.6981 16.3531 10.6981C16.7242 10.6981 17.0413 10.4155 17.0774 10.0387C17.1181 9.60783 16.7759 9.23334 16.3377 9.24184Z"
          fill="#00ADFB"
        />
        <path
          d="M16.8385 7.16354C16.75 6.83321 16.4479 6.61726 16.1206 6.62393C16.3894 7.10254 16.0516 7.69779 15.5 7.70889C15.6284 7.93786 15.8723 8.08041 16.1346 8.08041C16.1969 8.08041 16.2603 8.0724 16.3236 8.05567C16.7118 7.9515 16.9426 7.55205 16.8385 7.16354V7.16354Z"
          fill="#00ADFB"
        />
        <path
          d="M15.6046 4.55431C15.4594 4.35134 15.2294 4.24565 14.9977 4.25014C15.2683 4.7298 14.9255 5.3243 14.3779 5.33485C14.512 5.573 14.7578 5.70643 15.0132 5.70643C15.6006 5.7064 15.9505 5.03748 15.6046 4.55431V4.55431Z"
          fill="#00ADFB"
        />
        <path
          d="M13.5364 2.54284C13.4069 2.4556 13.2594 2.41602 13.1143 2.41911C13.2447 2.65153 13.243 2.94685 13.0837 3.18259C12.9464 3.38637 12.724 3.49815 12.4956 3.50326C12.6172 3.71902 12.8456 3.87481 13.1286 3.87481C13.3627 3.87481 13.5925 3.76231 13.733 3.55365C13.9581 3.22051 13.8699 2.76788 13.5364 2.54284V2.54284Z"
          fill="#00ADFB"
        />
        <path
          d="M15.7116 11.7752C15.6824 11.7723 15.6535 11.7713 15.625 11.7719C15.6849 11.8783 15.7188 12.0002 15.7188 12.1288C15.7188 12.5164 15.4082 12.8486 15.0054 12.8566C15.1306 13.0783 15.3683 13.2281 15.6399 13.2281C16.0499 13.2281 16.3681 12.8922 16.3681 12.4998C16.3681 12.133 16.0967 11.8175 15.7116 11.7752V11.7752Z"
          fill="#00ADFB"
        />
        <path
          d="M14.6888 13.8893C14.5441 13.7704 14.3663 13.716 14.1914 13.7245C14.3583 14.0027 14.3247 14.3721 14.0805 14.6156C12.6235 16.0684 10.6615 16.9196 8.58584 16.9775C7.25882 17.0146 5.99798 16.7312 4.87744 16.1975C6.15221 16.9565 7.65085 17.3748 9.24525 17.3303C11.3209 17.2723 13.2829 16.4211 14.7399 14.9684C15.0451 14.6641 15.0216 14.163 14.6888 13.8893V13.8893Z"
          fill="#00ADFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_983_196">
          <rect
            width="16.6667"
            height="16.6667"
            fill="white"
            transform="translate(0.666016 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OngoingTwoTone;
