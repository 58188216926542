import { domain } from "utils/domain";
import Cookies from "js-cookie";

const authRefreshKey = "refresh_auth";

export const enableRefreshAuth = () => {
  Cookies.set(authRefreshKey, "true", { path: "/", domain: `.${domain}` });
};

export const disableRefreshAuth = () => {
  localStorage.removeItem(authRefreshKey);
  Cookies.remove(authRefreshKey, { path: "/", domain: `.${domain}` });
};

export const checkShouldRefreshAuth = () => {
  return Cookies.get(authRefreshKey) === "true";
};
