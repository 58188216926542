import { FC, useState } from "react";
import styles from "./styles.module.css";
import clsx from "clsx";

import {
  Layout as AntdLayout,
  Row,
  Col,
  Space,
  Input,
  Spin,
  message,
} from "antd";
import { Link } from "components/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

import { memo } from "react";
import { routesEnum } from "pages/Routes";
import { useUser } from "api/auth";
import ArrowRight from "assets/svgs/ArrowRight";
import { useMutation } from "react-query";
import { subscribeNewsLetterRequest } from "api/user";
import { ISettings } from "pages/SettingPage/components/SettingSelection";

const Footer: FC = () => {
  const { data: me } = useUser({ enabled: false });
  const [email, setEmail] = useState("");

  const { mutate, isLoading } = useMutation(subscribeNewsLetterRequest, {
    onSuccess: () => {
      message.success("Subscribe successful!");
      setEmail("");
    },
  });

  const handleSubmit = () => {
    if (/^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      mutate({ email });
    } else {
      message.error("Invalid email address");
    }
  };
  return (
    <AntdLayout.Footer className="relative" id="footer">
      <div className={clsx(styles.content, "container-lg")}>
        <Row justify="space-between" align="top" gutter={[43, 32]}>
          <Col xs={12} lg={5}>
            <Space direction="vertical" size={22}>
              <div className={styles.headline}>Hackathons & Projects</div>

              <Link to={routesEnum.home} className={styles.text}>
                Explore Hackathons
              </Link>
              <Link to={routesEnum.createHackathon} className={styles.text}>
                Host a Hackathon
              </Link>
              <Link to={routesEnum.software} className={styles.text}>
                Explore Project
              </Link>

              <Link
                to={
                  me?.userName
                    ? `${routesEnum.portfolio}/${me?.userName}?action=add-project`
                    : `${routesEnum.signIn}?redirect=add-project`
                }
                className={styles.text}
              >
                Add Your Project
              </Link>
            </Space>
          </Col>

          <Col xs={12} lg={5}>
            <Space direction="vertical" size={22}>
              <div className={styles.headline}>Portfolio</div>

              <Link
                to={
                  me
                    ? `${routesEnum.portfolio}/${me?.userName}`
                    : routesEnum.signIn
                }
                className={styles.text}
              >
                Your Portfolio
              </Link>

              <Link
                to={
                  me
                    ? `${routesEnum.portfolio}/${me?.userName}/?tab=hackathon`
                    : routesEnum.signIn
                }
                className={styles.text}
              >
                Your Hackathons
              </Link>

              <Link
                to={
                  me
                    ? `${routesEnum.portfolio}/${me?.userName}`
                    : routesEnum.signIn
                }
                className={styles.text}
              >
                Your Projects
              </Link>

              <Link
                to={me ? `${routesEnum.settings}` : routesEnum.signIn}
                className={styles.text}
              >
                Your Settings
              </Link>

              <Link
                to={
                  me
                    ? `${routesEnum.settings}?tab=${ISettings.preferences}`
                    : routesEnum.signIn
                }
                className={styles.text}
              >
                Your Preferences
              </Link>
            </Space>
          </Col>
          <Col xs={12} lg={5}>
            <Space direction="vertical" size={22}>
              <div className={styles.headline}>Company</div>

              <Link to={routesEnum.about} className={styles.text}>
                About Us
              </Link>
              <Link to={routesEnum.contact} className={styles.text}>
                Contact
              </Link>
              <Link to={"/help"} className={styles.text}>
                Help Docs
              </Link>

              <Link to={routesEnum.policy} className={styles.text}>
                Privacy policy
              </Link>
              <Link to={routesEnum.term} className={styles.text}>
                Terms & conditions
              </Link>
            </Space>
          </Col>

          <Col xs={24} md={12} lg={7}>
            <Space direction="vertical" size={22}>
              <div className={styles.headline}>
                <span>Subscribe to Newsletter</span>
              </div>
              <Spin spinning={isLoading}>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="E-mail  Address"
                  className={styles.mailInput}
                  addonAfter={
                    <div
                      style={{ height: 62, width: 60, lineHeight: "62px" }}
                      onClick={handleSubmit}
                    >
                      <ArrowRight />
                    </div>
                  }
                />
              </Spin>
            </Space>
          </Col>
        </Row>

        <div className={styles.divider} />

        <div className={styles.copyRightWrapper}>
          <div>
            <span>
              <a
                href="https://rebaked.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                rebaked.com
              </a>
              &nbsp;|&nbsp;OWNERSHIP ECONOMY LIMITED
            </span>
          </div>

          {/* <Space wrap size={40}>
            <Link to={routesEnum.landingPage} className={styles.text}>
              Privacy policy
            </Link>
            <Link to={routesEnum.landingPage} className={styles.text}>
              CA notice
            </Link>
            <Link to={routesEnum.landingPage} className={styles.text}>
              Community guidelines
            </Link>
            <Link to={routesEnum.landingPage} className={styles.text}>
              Terms and conditions
            </Link>
          </Space> */}
        </div>
      </div>
    </AntdLayout.Footer>
  );
};

export default memo(Footer);
