import { FC, memo, useMemo } from "react";
import { INotification } from "api/notification";
import styles from "./NotificationItem.module.css";
import { Surface } from "components/Surface";
import { Avatar } from "components/Avatar";
import { Space } from "antd";
import { getRelativeTime } from "utils/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faHeart,
  faQuoteLeft,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getImageUrl } from "utils/urlParser";
import clsx from "clsx";
import { routesEnum, hackathonDetailParams } from "pages/Routes";
import { useDomainRoute } from "hooks";
import { getHackathonDomain } from "utils/domain";
import { Link } from "components/Link";

const NotificationItem: FC<{ data: INotification }> = ({ data }) => {
  const [push] = useDomainRoute();
  const notificationIcon = useMemo(() => {
    switch (data.iconType) {
      case "like":
        return <FontAwesomeIcon icon={faHeart} />;
      case "comment":
        return <FontAwesomeIcon icon={faQuoteLeft} />;
      case "submit_project":
        return <FontAwesomeIcon icon={faCalendarAlt} />;
      default:
        return <FontAwesomeIcon icon={faUser} />;
    }
  }, [data]);

  const message = useMemo(() => {
    switch (data.iconType) {
      case "like":
        return (
          <span>
            <span className="regular black">{data.userFullName}</span>{" "}
            {data.message}
            <span className="regular black">&nbsp;{data.projectName}</span>
            &nbsp;
          </span>
        );
      case "comment":
        return (
          <span>
            <span className="regular black">{data.userFullName}</span>{" "}
            {data.message}
            <span className="regular black">&nbsp;{data.projectName}</span>
            &nbsp;
          </span>
        );
      case "submit_project":
        return (
          <span>
            <span className="regular black">
              {data?.hackathonId?.essentials?.name || data.projectName}
            </span>{" "}
            had new submission &nbsp;
          </span>
        );
      default:
        return (
          <span>
            <span className="regular black">{data.userFullName}</span>&nbsp;{" "}
            {data.message}&nbsp;
          </span>
        );
    }
  }, [data]);

  const href = useMemo(() => {
    switch (data.iconType) {
      case "like":
        return `${routesEnum.project}/${data.projectId}`;
      case "comment":
        return `${routesEnum.project}/${data.projectId}`;
      case "submit_project":
        return `${
          getHackathonDomain(data?.hackathonId?.essentials?.url) +
          routesEnum.hackathon
        }/${hackathonDetailParams.projectGallery}/${
          data?.hackathonId?._id
        }/detail?submissionId=${data?.submissionId}`;
      default:
        return `${routesEnum.portfolio}/${data.username}`;
    }
  }, [data]);

  const thumbnail = useMemo(() => {
    switch (data.iconType) {
      case "submit_project":
        return data.thumbnailHackathon || "";
      default:
        return data.thumbnail || "";
    }
  }, [data]);

  return (
    <CustomLink type={data?.iconType} href={href}>
      <Surface
        className={clsx({
          [styles.item]: true,
          [styles.unWatch]: !data.isWatched,
        })}
      >
        <Space size={12}>
          <div className={styles.avatarWrap}>
            <Avatar
              icon={notificationIcon}
              border
              className={clsx(styles.type, styles[data.iconType || "user"])}
              size={20}
            />
            <Avatar
              className={styles.thumbnail}
              src={getImageUrl(thumbnail || data.avatar || "")}
              border={false}
              size={64}
              icon={<></>}
            />
          </div>
          <Space direction="vertical" size={0}>
            <div className={styles.message}>{message}</div>
            <div className="ghost text-12">
              {getRelativeTime(data.createdAt)}
            </div>
          </Space>
        </Space>

        {/* <Hidden js cond={data.iconType !== "user"}>
          <Image
            className={styles.thumbnail}
            src={getImageUrl(thumbnail || "")}
          />
        </Hidden> */}
      </Surface>
    </CustomLink>
  );
};

export default memo(NotificationItem);

const CustomLink: FC<{ type: any; href: string }> = ({
  type,
  href,
  children,
}) => {
  if (type === "submit_project")
    return (
      <a className="default" href={href}>
        {children}
      </a>
    );
  return (
    <Link className="default" to={href}>
      {children}
    </Link>
  );
};
