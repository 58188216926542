import clsx from "clsx";
import React from "react";
import styles from "./styles.module.css";

export interface SurfaceProps {
  type?: "primary" | "secondary";
  bordered?: boolean;
  className?: string;
  hoverable?: boolean;
  style?: React.CSSProperties;
  onClick?: (e: any) => void;
  rounded?: number;
}

export const Surface: React.FC<SurfaceProps> = ({
  className,
  children,
  type = "primary",
  hoverable,
  bordered = false,
  rounded,
  onClick,
  style,
}) => {
  return (
    <div
      className={clsx(
        styles.surface,
        styles[type],
        hoverable && styles.hoverable,
        bordered && styles.bordered,
        rounded && styles[`rounded_${rounded}`],
        className
      )}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

Surface.defaultProps = {};
