import axios from "api/axios";
import { getTzFormat, ITimezoneFormat } from "utils/dateUtils";
import { parameterizeArray } from "utils/urlParser";

import {
  ICreateHackathonParams,
  IUpdateEssentialParams,
  IHackathonResponse,
  IUpdateDesignParams,
  IUpdateSiteParams,
  IUpdateTodosParams,
  IUpdateStartKitParams,
  IUpdateSubmissionParams,
  IUpdatePublicVotingParams,
  IUpdatePrizeParams,
  IUpdateEligibilityParams,
  ISponsor,
  IJudges,
  IJudgeCriteria,
  IAward,
  IUpdateJudgingParams,
  ICustomFormQuestion,
  IListHackathonPayload,
  IListParticipantParams,
  IParticipant,
  ICreateTopicParams,
  IHackathonTopic,
  IUpdateTopicParams,
  IJoinHackathonParams,
  ITodoStatus,
  ITodoStatusResponse,
  ICreateHackathonUpdate,
  IHackathonUpdate,
  IUpdateHackathonUpdate,
  IHackathonListResponse,
  IJudgingSubmission,
  IMarkSubmissionPayload,
  IListHackathonProjectGalleryPayload,
  IListHackathonProjectGallery,
  IFlagDiscussion,
  IChangeTeamUpStatusParams,
  ISchedule,
} from "./types";

export const createHackathonRequest = async (
  params: ICreateHackathonParams
) => {
  return await axios({
    url: "/hackathon/create",
    method: "POST",
    data: params,
  });
};

export const getHackathonRequest = async (
  hackathonId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/detail/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const getListHostHackathonRequest = async (): Promise<
  IHackathonResponse[]
> => {
  const { data } = await axios({
    url: `/hackathon/list_host_hackathon`,
    method: "GET",
  });
  return data;
};

export const getListHackathonRequest = async (
  params: IListHackathonPayload
): Promise<IHackathonListResponse> => {
  const param =
    parameterizeArray("lengths", params.lengths) +
    parameterizeArray("themeTags", params.themeTags) +
    parameterizeArray("status", params.status);
  const orderBy = `order_by=${params?.order_by || "relevant"}`;
  const searchBy = params.search ? `&search=${params?.search}` : "";
  const eligibility = params.eligibility ? `&eligibility=1` : "";
  const host = params.host ? `&host=${params.host}` : "";
  const isHide = params.isHide !== undefined ? `&is_hide=${params.isHide}` : "";
  const { data } = await axios({
    url: `/hackathon/list_hackathon?${orderBy}${searchBy}${eligibility}${isHide}${host}${param}&page=${params.page}&pageSize=${params.pageSize}`,
    method: "GET",
  });
  return data;
};

export const deleteHackathon = async (hackathonId: string): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/remove/${hackathonId}`,
    method: "DELETE",
  });
  return data;
};

export const publishHackathonRequest = async (params: {
  hackathonId: string;
  transactionId?: string;
}): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/publish`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sendReviewHackathonRequest = async (params: {
  hackathonId: string;
  transactionId?: string;
}): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/review-hackathon`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateEssentialRequest = async (
  hackathonId: string,
  params: IUpdateEssentialParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/essential/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateEligibilityRequest = async (
  hackathonId: string,
  params: IUpdateEligibilityParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/eligibility/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateDesignRequest = async (
  hackathonId: string,
  params: IUpdateDesignParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/design/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateHackathonSiteRequest = async (
  hackathonId: string,
  params: IUpdateSiteParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/site/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateTodosRequest = async (
  hackathonId: string,
  params: IUpdateTodosParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/todo/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateStartKitRequest = async (
  hackathonId: string,
  params: IUpdateStartKitParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/start_kit/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateSubmissionRequest = async (
  hackathonId: string,
  params: IUpdateSubmissionParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/submission/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updatePublicVotingRequest = async (
  hackathonId: string,
  params: IUpdatePublicVotingParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/vote/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updateJudgingRequest = async (
  hackathonId: string,
  params: IUpdateJudgingParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/judging/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const updatePrizesRequest = async (
  hackathonId: string,
  params: IUpdatePrizeParams
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/prizes/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

// Sponsor
export const createSponsorRequest = async (
  hackathonId: string,
  params: ISponsor
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sponsor/${hackathonId}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateSponsorRequest = async (
  hackathonId: string,
  params: ISponsor
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sponsor/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sortSponsorRequest = async (
  hackathonId: string,
  params: string[]
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sort_sponsor/${hackathonId}`,
    method: "PUT",
    data: {
      sponsor: params,
    },
  });
  return data;
};

export const deleteSponsorRequest = async (
  hackathonId: string,
  sponsorId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sponsor/${hackathonId}`,
    method: "DELETE",
    data: { _id: sponsorId },
  });
  return data;
};

// Judges

export const createJudgeRequest = async (
  hackathonId: string,
  params: IJudges
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/judges/${hackathonId}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateJudgeRequest = async (
  hackathonId: string,
  params: IJudges
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/judges/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sortJudgeRequest = async (
  hackathonId: string,
  params: string[]
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sort_judges/${hackathonId}`,
    method: "PUT",
    data: {
      judges: params,
    },
  });
  return data;
};

export const deleteJudgeRequest = async (
  hackathonId: string,
  sponsorId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/judges/${hackathonId}`,
    method: "DELETE",
    data: { _id: sponsorId },
  });
  return data;
};

// JudgeCriteria

export const createJudgeCriteriaRequest = async (
  hackathonId: string,
  params: IJudgeCriteria
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/criteria/${hackathonId}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateJudgeCriteriaRequest = async (
  hackathonId: string,
  params: IJudgeCriteria
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/criteria/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sortJudgeCriteriaRequest = async (
  hackathonId: string,
  params: string[]
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sort_criteria/${hackathonId}`,
    method: "PUT",
    data: {
      criteria: params,
    },
  });
  return data;
};
export const deleteJudgeCriteriaRequest = async (
  hackathonId: string,
  criteriaId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/criteria/${hackathonId}`,
    method: "DELETE",
    data: { _id: criteriaId },
  });
  return data;
};

// Prize

export const createPrizeRequest = async (
  hackathonId: string,
  params: IAward
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/prize/${hackathonId}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updatePrizeRequest = async (
  hackathonId: string,
  params: IAward
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/prize/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sortPrizeRequest = async (
  hackathonId: string,
  params: string[]
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sort_prize/${hackathonId}`,
    method: "PUT",
    data: { awards: params },
  });
  return data;
};

export const deletePrizeRequest = async (
  hackathonId: string,
  criteriaId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/prize/${hackathonId}`,
    method: "DELETE",
    data: { _id: criteriaId },
  });
  return data;
};

// Registration form question

export const createRegistrationQuestionRequest = async (
  hackathonId: string,
  params: ICustomFormQuestion
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/custom_question/${hackathonId}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateRegistrationQuestionRequest = async (
  hackathonId: string,
  params: ICustomFormQuestion
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/custom_question/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sortRegistrationQuestionRequest = async (
  hackathonId: string,
  params: string[]
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sort_custom_question/${hackathonId}`,
    method: "PUT",
    data: { customQuestion: params },
  });
  return data;
};

export const deleteRegistrationQuestionRequest = async (
  hackathonId: string,
  questionId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/custom_question/${hackathonId}`,
    method: "DELETE",
    data: { _id: questionId },
  });
  return data;
};

// Registration form question

export const createCustomQuestionRequest = async (
  hackathonId: string,
  params: ICustomFormQuestion
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/custom_form_question/${hackathonId}`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateCustomQuestionRequest = async (
  hackathonId: string,
  params: ICustomFormQuestion
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/custom_form_question/${hackathonId}`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const sortCustomQuestionRequest = async (
  hackathonId: string,
  params: string[]
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/sort_custom_form_question/${hackathonId}`,
    method: "PUT",
    data: { customFormQuestion: params },
  });
  return data;
};

export const deleteCustomQuestionRequest = async (
  hackathonId: string,
  questionId: string
): Promise<IHackathonResponse> => {
  const { data } = await axios({
    url: `/hackathon/custom_form_question/${hackathonId}`,
    method: "DELETE",
    data: { _id: questionId },
  });
  return data;
};

export const joinHackathonRequest = async (
  params: IJoinHackathonParams
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/createParticipant`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const changeTeammateStatusRequest = async (
  params: IChangeTeamUpStatusParams
): Promise<any> => {
  const { data } = await axios({
    url: `/participant/change-status-teammate`,
    method: "POST",
    data: params,
  });
  return data;
};

export const getHackathonParticipant = async (
  hackathonId: string,
  params: IListParticipantParams
): Promise<IParticipant> => {
  const param = parameterizeArray("skills", params.skills);
  const specialty = parameterizeArray("specialty", params.specialty);
  const sortBy = `sortBy=${params?.sortBy || "Newest"}`;
  const searchBy = params.search ? `&search=${params?.search}` : "";
  const teamForm = params.teamForm ? `&teammate=${params?.teamForm}` : "";
  const { data } = await axios({
    url: `/hackathon/participant/${hackathonId}?${sortBy}${searchBy}${specialty}${param}${teamForm}`,
    method: "GET",
  });
  return data;
};

// Topic

export const createTopicRequest = async (
  params: ICreateTopicParams
): Promise<any> => {
  const { data } = await axios({
    url: `/topic/create`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateTopicRequest = async (
  params: IUpdateTopicParams
): Promise<any> => {
  const { data } = await axios({
    url: `/topic/update`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const getTopicRequest = async (
  topicId: string
): Promise<IHackathonTopic> => {
  const { data } = await axios({
    url: `topic/detail/${topicId}`,
    method: "GET",
  });
  return data;
};

export const getListTopicRequest = async (
  hackathonId: string
): Promise<IHackathonTopic[]> => {
  const { data } = await axios({
    url: `/topic/hackathon_list/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const checkAvailabilityRequest = async (
  url: string
): Promise<boolean> => {
  const { data } = await axios({
    url: `/hackathon/check_availability/${url}`,
    method: "GET",
  });
  return data;
};

export const updateTodoStatusRequest = async (
  hackathonId: string,
  params: ITodoStatus[]
): Promise<ITodoStatus[]> => {
  const { data } = await axios({
    url: `/participant/todo/${hackathonId}`,
    method: "PUT",
    data: {
      todo: params,
    },
  });
  return data;
};

export const getTodoStatusRequest = async (
  hackathonId: string
): Promise<ITodoStatusResponse> => {
  const { data } = await axios({
    url: `/participant/todo/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const sendTestEmailRequest = async (params: {
  hackathonId: string;
  email: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/sendmail_startkit/${params.hackathonId}?email=${params.email}`,
    method: "GET",
  });
  return data;
};

export const createHackathonUpdateRequest = async (
  params: ICreateHackathonUpdate
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon-update/create`,
    method: "POST",
    data: params,
  });
  return data;
};

export const updateHackathonUpdateRequest = async (
  params: IUpdateHackathonUpdate
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon-update/update`,
    method: "PUT",
    data: params,
  });
  return data;
};

export const deleteHackathonUpdateRequest = async (
  updateId: string
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon-update/remove/${updateId}`,
    method: "DELETE",
  });
  return data;
};

export const getListHackathonUpdateRequest = async (
  hackathonId: string
): Promise<IHackathonUpdate[]> => {
  const { data } = await axios({
    url: `/hackathon-update/find_all/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const getListHackathonUpdatePublishedRequest = async (
  hackathonId: string
): Promise<IHackathonUpdate[]> => {
  const { data } = await axios({
    url: `/hackathon-update/find_all_publish/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const getHackathonUpdateRequest = async (
  updateId: string
): Promise<IHackathonUpdate> => {
  const { data } = await axios({
    url: `/hackathon-update/find_detail/${updateId}`,
    method: "GET",
  });
  return data;
};

export const getHackathonTimezoneRequest = async (
  hackathonId: string
): Promise<ITimezoneFormat> => {
  const { data } = await axios({
    url: `/hackathon/get_timezone/${hackathonId}`,
    method: "GET",
  });
  return getTzFormat(data.timeZone);
};

export const sendUpdateEmailHackathonRequest = async (params: {
  hackathonUpdateId: string;
  email: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon-update/sendmail_update_hackathon/${params.hackathonUpdateId}?email=${params.email}`,
    method: "GET",
  });
  return getTzFormat(data.timeZone);
};

export const voteProjectRequest = async (params: {
  hackathonId: string;
  projectId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/project/vote_project`,
    method: "POST",
    data: params,
  });
  return data;
};

export const getListJudgingSubmission = async (
  hackathonId: string,
  judgeId: string
): Promise<IJudgingSubmission[]> => {
  const { data } = await axios({
    url: `/hackathon/get_judging_process/${hackathonId}/${judgeId}`,
    method: "GET",
  });
  return data;
};

export const markSubmissionRequest = async (
  params: IMarkSubmissionPayload
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/mark_submission`,
    method: "POST",
    data: params,
  });
  return data;
};

export const getListHackathonProjectGalleryRequest = async (
  hackathonId: string,
  params: IListHackathonProjectGalleryPayload
): Promise<IListHackathonProjectGallery> => {
  // const param =
  //   parameterizeArray("lengths", params.lengths) +
  //   parameterizeArray("themeTags", params.themeTags) +
  //   parameterizeArray("status", params.status);

  const filter_categories = params.filter_categories
    ? `&filter_categories=${params?.filter_categories}`
    : "";
  const childTag = parameterizeArray("filter_value", params.childTag);

  const { data } = await axios({
    url: `/hackathon/project_gallery?hackathonId=${hackathonId}&page=${params.page}&pageSize=${params.pageSize}&search=${params.search}${filter_categories}${childTag}`,
    method: "GET",
  });
  return data;
};

export const getListFlagDiscussion = async (
  hackathonId: string
): Promise<IFlagDiscussion[]> => {
  const { data } = await axios({
    url: `/topic/hackathon_list_flag/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const removeTopicFlagRequest = async (params: {
  topicId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/topic/flag`,
    method: "DELETE",
    data: params,
  });
  return data;
};

export const removeCommentFlagRequest = async (params: {
  commentId: string;
  topicId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/comment/flag`,
    method: "DELETE",
    data: params,
  });
  return data;
};

export const refreshHackathonStatusRequest = async (
  hackathonId: string
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/refresh_status_hackathon/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const sendEmailHackathonReadyRequest = async (
  hackathonId: string
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/sendmail_ready_publish/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const flagPublishHackathonRequest = async (params: {
  hackathonId: string;
  flag_publish: boolean;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/flag_publish`,
    method: "POST",
    data: params,
  });
  return data;
};

export const refreshProjectStatusRequest = async (
  hackathonId: string
): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/refresh_status_project_submit_hackathon/${hackathonId}`,
    method: "GET",
  });
  return data;
};

export const removeHackathonRequest = async (params: {
  hktId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/remove/${params.hktId}`,
    method: "DELETE",
  });
  return data;
};

export const adminHideHackathon = async (params: {
  hktId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/admin_hide_toggle/${params.hktId}`,
    method: "PUT",
  });
  return data;
};

export const addCustomSchedule = async (params: {
  hktId: string;
  schedule: ISchedule;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/schedule/${params.hktId}`,
    method: "POST",
    data: params.schedule,
  });
  return data;
};

export const editCustomSchedule = async (params: {
  hktId: string;
  schedule: ISchedule;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/schedule/${params.hktId}`,
    method: "PUT",
    data: params.schedule,
  });
  return data;
};

export const deleteCustomSchedule = async (params: {
  hktId: string;
  scheduleId: string;
}): Promise<any> => {
  const { data } = await axios({
    url: `/hackathon/schedule/${params.hktId}/${params.scheduleId}`,
    method: "DELETE",
  });
  return data;
};
