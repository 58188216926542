import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from "react-query";
import { ITimezoneFormat } from "utils/dateUtils";
import {
  getHackathonParticipant,
  getHackathonRequest,
  getListHackathonRequest,
  getListHostHackathonRequest,
  getListTopicRequest,
  getTodoStatusRequest,
  getTopicRequest,
  getListHackathonUpdateRequest,
  getHackathonUpdateRequest,
  getHackathonTimezoneRequest,
  getListHackathonUpdatePublishedRequest,
  getListHackathonProjectGalleryRequest,
  getListJudgingSubmission,
  getListFlagDiscussion,
  refreshHackathonStatusRequest,
  refreshProjectStatusRequest,
} from "./request";
import {
  IListParticipantParams,
  IHackathonResponse,
  IListHackathonPayload,
  IParticipant,
  IHackathonTopic,
  ITodoStatusResponse,
  IHackathonUpdate,
  IHackathonListResponse,
  IJudgingSubmission,
  IListHackathonProjectGallery,
  IListHackathonProjectGalleryPayload,
  IFlagDiscussion,
} from "./types";

export const useHackathon = (
  id: string,
  option?: UseQueryOptions<IHackathonResponse, Error>
) => {
  return useQuery<IHackathonResponse, Error>(
    ["hackathon", id],
    () => getHackathonRequest(id),
    option
  );
};

export const useListHostHackathon = (
  option?: UseQueryOptions<IHackathonResponse[], Error>
) => {
  return useQuery<IHackathonResponse[], Error>(
    "hackathons",
    getListHostHackathonRequest,
    option
  );
};

export const useListHackathon = (
  filterParams: IListHackathonPayload,
  option?: UseQueryOptions<IHackathonListResponse, Error>
) => {
  return useQuery<IHackathonListResponse, Error>(
    ["listHackathon", filterParams],
    () => getListHackathonRequest(filterParams),
    option
  );
};

export const useInfinityListHackathon = (
  filterParams: IListHackathonPayload,
  option?: UseInfiniteQueryOptions<IHackathonListResponse, Error>
) => {
  return useInfiniteQuery<IHackathonListResponse, Error>(
    ["listInfinityHackathon", filterParams],
    ({ pageParam = 0 }) => {
      const params = { ...filterParams };
      params.page = pageParam;
      return getListHackathonRequest(params);
    },
    option
  );
};

export const useListParticipant = (
  hackathonId: string,
  filterParams: IListParticipantParams,
  option?: UseQueryOptions<IParticipant, Error>
) => {
  return useQuery<IParticipant, Error>(
    ["hackathonParitcipant", { ...filterParams, hackathonId }],
    () => getHackathonParticipant(hackathonId, filterParams),
    option
  );
};

export const useListTopic = (
  hackathonId: string,
  option?: UseQueryOptions<IHackathonTopic[], Error>
) => {
  return useQuery<IHackathonTopic[], Error>(
    ["listTopic", hackathonId],
    () => getListTopicRequest(hackathonId),
    option
  );
};

export const useTopic = (
  topicId: string,
  option?: UseQueryOptions<IHackathonTopic, Error>
) => {
  return useQuery<IHackathonTopic, Error>(
    ["topic", topicId],
    () => getTopicRequest(topicId),
    option
  );
};

export const useTodoStatus = (
  hachathonId: string,
  option?: UseQueryOptions<ITodoStatusResponse, Error>
) => {
  return useQuery<ITodoStatusResponse, Error>(
    ["todoStatus", hachathonId],
    () => getTodoStatusRequest(hachathonId),
    option
  );
};

export const useListHackathonUpdate = (
  hachathonId: string,
  option?: UseQueryOptions<IHackathonUpdate[], Error>
) => {
  return useQuery<IHackathonUpdate[], Error>(
    ["hackathonUpdates", hachathonId],
    () => getListHackathonUpdateRequest(hachathonId),
    option
  );
};

export const useListHackathonUpdatePublished = (
  hachathonId: string,
  option?: UseQueryOptions<IHackathonUpdate[], Error>
) => {
  return useQuery<IHackathonUpdate[], Error>(
    ["hackathonUpdatePublished", hachathonId],
    () => getListHackathonUpdatePublishedRequest(hachathonId),
    option
  );
};

export const useHackathonUpdate = (
  updateId: string,
  option?: UseQueryOptions<IHackathonUpdate, Error>
) => {
  return useQuery<IHackathonUpdate, Error>(
    ["hackathonUpdate", updateId],
    () => getHackathonUpdateRequest(updateId),
    option
  );
};

export const useHackathonTimezone = (
  hackathonId: string,
  option?: UseQueryOptions<ITimezoneFormat, Error>
) => {
  return useQuery<ITimezoneFormat, Error>(
    ["hackathonTimezone", hackathonId],
    () => getHackathonTimezoneRequest(hackathonId),
    option
  );
};

export const useListJudgingSubmission = (
  hackathonId: string,
  judgeId: string,
  option?: UseQueryOptions<IJudgingSubmission[], Error>
) => {
  return useQuery<IJudgingSubmission[], Error>(
    ["judgeProcess", hackathonId, judgeId],
    () => getListJudgingSubmission(hackathonId, judgeId),
    option
  );
};

export const useInfinityListProjectGallery = (
  hackathonId: string,
  filterParams: IListHackathonProjectGalleryPayload,
  option?: UseInfiniteQueryOptions<IListHackathonProjectGallery, Error>
) => {
  return useInfiniteQuery<IListHackathonProjectGallery, Error>(
    ["listInfinityProjectGallery", hackathonId, filterParams],
    ({ pageParam = 0 }) => {
      const params = { ...filterParams };
      params.page = pageParam;
      return getListHackathonProjectGalleryRequest(hackathonId, params);
    },
    option
  );
};

export const useListFlagDiscussion = (
  hackathonId: string,
  option?: UseQueryOptions<IFlagDiscussion[], Error>
) => {
  return useQuery<IFlagDiscussion[], Error>(
    ["listFlagDiscussion", hackathonId],
    () => getListFlagDiscussion(hackathonId),
    option
  );
};

export const useRefreshHackathonStatus = (
  hackathonId: string,
  option?: UseQueryOptions<any, Error>
) => {
  return useQuery<any, Error>(
    ["refreshHackathonStatus", hackathonId],
    () => refreshHackathonStatusRequest(hackathonId),
    option
  );
};

export const useRefreshProjectStatus = (
  hackathonId: string,
  option?: UseQueryOptions<any, Error>
) => {
  return useQuery<any, Error>(
    ["refreshProjectStatus", hackathonId],
    () => refreshProjectStatusRequest(hackathonId),
    option
  );
};
