import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IListHackathonProjectGalleryPayload,
  LIST_HACKATHON_PAGE_SIZE,
} from "api/hackathon";
import { RootState } from "store";

const initialState = {
  search: "",
  sortBy: "",
  page: 0,
  pageSize: LIST_HACKATHON_PAGE_SIZE,
  childTag: [],
  filter_categories: "",
} as IListHackathonProjectGalleryPayload;

const filterGallerySlice = createSlice({
  name: "filterGallery",
  initialState,
  reducers: {
    setSortBy(state, action: PayloadAction<string>) {
      return {
        ...state,
        sortBy: action.payload,
      };
    },
    setSearch(state, action: PayloadAction<string>) {
      return {
        ...state,
        search: action.payload,
      };
    },
    setTags: (
      state: IListHackathonProjectGalleryPayload,
      actions: PayloadAction<string[]>
    ) => {
      return { ...state, childTag: actions.payload };
    },
    setCategory: (
      state: IListHackathonProjectGalleryPayload,
      actions: PayloadAction<string>
    ) => {
      return { ...state, filter_categories: actions.payload };
    },
  },
});

export const getFilterGalleryPayload = (
  state: RootState
): IListHackathonProjectGalleryPayload => {
  return state.filterGallery;
};

export const { setSortBy, setSearch, setCategory, setTags } =
  filterGallerySlice.actions;

export default filterGallerySlice.reducer;
