import { FC } from "react";

const Badge: FC<{ color?: string; className?: string }> = ({
  color = "black",
  className,
}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17 15.745V22.617C17 22.7054 16.9766 22.7923 16.932 22.8688C16.8875 22.9452 16.8235 23.0085 16.7465 23.0521C16.6696 23.0957 16.5824 23.1181 16.4939 23.117C16.4055 23.116 16.3189 23.0914 16.243 23.046L12 20.5L7.757 23.046C7.68104 23.0915 7.59436 23.116 7.50581 23.117C7.41727 23.1181 7.33004 23.0956 7.25304 23.0518C7.17605 23.0081 7.11205 22.9447 7.06759 22.8681C7.02313 22.7915 6.9998 22.7045 7 22.616V15.746C5.70615 14.7101 4.76599 13.2979 4.30946 11.7045C3.85293 10.1112 3.90256 8.41536 4.45149 6.85145C5.00043 5.28753 6.02156 3.93272 7.37378 2.97425C8.726 2.01578 10.3425 1.50098 12 1.50098C13.6575 1.50098 15.274 2.01578 16.6262 2.97425C17.9784 3.93272 18.9996 5.28753 19.5485 6.85145C20.0974 8.41536 20.1471 10.1112 19.6905 11.7045C19.234 13.2979 18.2939 14.7101 17 15.746V15.745ZM12 15.5C13.5913 15.5 15.1174 14.8678 16.2426 13.7426C17.3679 12.6174 18 11.0913 18 9.49997C18 7.90868 17.3679 6.38255 16.2426 5.25733C15.1174 4.13212 13.5913 3.49997 12 3.49997C10.4087 3.49997 8.88258 4.13212 7.75736 5.25733C6.63214 6.38255 6 7.90868 6 9.49997C6 11.0913 6.63214 12.6174 7.75736 13.7426C8.88258 14.8678 10.4087 15.5 12 15.5ZM12 13.5C10.9391 13.5 9.92172 13.0785 9.17157 12.3284C8.42143 11.5783 8 10.5608 8 9.49997C8 8.43911 8.42143 7.42169 9.17157 6.67155C9.92172 5.9214 10.9391 5.49997 12 5.49997C13.0609 5.49997 14.0783 5.9214 14.8284 6.67155C15.5786 7.42169 16 8.43911 16 9.49997C16 10.5608 15.5786 11.5783 14.8284 12.3284C14.0783 13.0785 13.0609 13.5 12 13.5Z"
        fill={color}
      />
    </svg>
  );
};

export default Badge;
