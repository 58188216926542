import { FC } from "react";

const ShareBox: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 7.33301V4.66634M14 1.33301H2V11.9997H5.33333V14.6663L8 11.9997H11.3333L14 9.33301V1.33301ZM7.33333 7.33301V4.66634V7.33301Z"
        stroke="#080A25"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareBox;
