import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/slice";
import filterHackathon from "./filterHackathon/slice";
import filterParticipant from "./filterParticipant/slice";
import filterSubmission from "./filterSubmission/slice";
import filterGallery from "./filterGallery/slice";
import walletModal from "./wallet-modal/slice";
import software from "./software/slice";

const createRootReducer = () => {
  return combineReducers({
    auth,
    filterHackathon,
    filterParticipant,
    filterSubmission,
    filterGallery,
    walletModal,
    software,
  });
};

export default createRootReducer;
