import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IListHackathonPayload, LIST_HACKATHON_PAGE_SIZE } from "api/hackathon";
import { RootState } from "store";

const initialState = {
  search: "",
  eligibility: false,
  order_by: "relevant",
  host: undefined,
  lengths: [],
  status: [],
  themeTags: [],
  page: 0,
  pageSize: LIST_HACKATHON_PAGE_SIZE,
} as IListHackathonPayload;

const filterHackathonSlice = createSlice({
  name: "filterHackathon",
  initialState,
  reducers: {
    setOrderBy(
      state,
      action: PayloadAction<"relevant" | "submission" | "added" | "prize">
    ) {
      return {
        ...state,
        order_by: action.payload,
      };
    },
    setSearch(state, action: PayloadAction<string>) {
      return {
        ...state,
        search: action.payload,
      };
    },
    setHost(state, action: PayloadAction<string>) {
      return {
        ...state,
        host: action.payload,
      };
    },
    setLength(state, action: PayloadAction<"week" | "day" | "month">) {
      return {
        ...state,
        lengths: [action.payload],
      };
    },
    setStatus(
      state,
      action: PayloadAction<"Upcoming" | "open" | "ended" | undefined>
    ) {
      return {
        ...state,
        status: !action.payload ? [] : [action.payload],
      };
    },
    setThemeTags(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        themeTags: action.payload,
      };
    },
    appendThemeTags(state, action: PayloadAction<string>) {
      return {
        ...state,
        themeTags: [...new Set([...state.themeTags, action.payload])],
      };
    },
    setEligibility(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        eligibility: action.payload,
      };
    },
    clearLengthAndStatus(state) {
      return {
        ...state,
        lengths: [],
        status: [],
      };
    },
    setPage(state, action: PayloadAction<number>) {
      return {
        ...state,
        page: action.payload,
      };
    },
  },
});

export const getFilterPayload = (state: RootState): IListHackathonPayload => {
  return state.filterHackathon;
};

export const getLengthAndStatus = (
  state: RootState
): { lengths: string[]; status: string[] } => {
  return {
    lengths: state.filterHackathon.lengths,
    status: state.filterHackathon.status,
  };
};

export const getSearchAndOrderBy = (
  state: RootState
): { search: string; order_by: string } => {
  return {
    search: state.filterHackathon.search,
    order_by: state.filterHackathon.order_by,
  };
};

export const getLeftFiler = (
  state: RootState
): {
  themeTags: string[];
  lengths: string[];
  eligibility: boolean;
  host?: string;
} => {
  return {
    themeTags: state.filterHackathon.themeTags,
    eligibility: state.filterHackathon.eligibility,
    host: state.filterHackathon.host,
    lengths: state.filterHackathon.lengths,
  };
};

export const {
  setOrderBy,
  setSearch,
  setEligibility,
  setLength,
  setStatus,
  setThemeTags,
  appendThemeTags,
  clearLengthAndStatus,
  setPage,
  setHost,
} = filterHackathonSlice.actions;
export default filterHackathonSlice.reducer;
