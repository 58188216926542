import dayjs, { Dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export const defaultTimezone = "America/Chicago";
export const formatType = "YYYY-MM-DD HH:mm:ss";
export const clientTimeOffset = dayjs().utcOffset();

export const getDisabledTime = () =>
  dayjs(dayjs().utc().format("YYYY-MM-DD")).valueOf();

export const getDisabledTimezone = (tz: string) =>
  dayjs(getCurrentUTCTime(tz).format("YYYY-MM-DD")).valueOf();

export const disabledPast = (current: Dayjs | any, tz?: string) => {
  if (tz) {
    return (
      current &&
      dayjs(dayjs(current).format("YYYY-MM-DD")).valueOf() <
        getDisabledTimezone(tz)
    );
  }
  return (
    current &&
    dayjs(dayjs(current).format("YYYY-MM-DD")).valueOf() < getDisabledTime()
  );
};

export const disabledFuture = (current: any) => {
  return current && current > Date.now();
};

export const dateFormat = (time: any) => {
  return dayjs(time).format("MMM D, YYYY [at] H:mm:ss");
};

// Convert time to UTC +-0 time
export const getUTCTime = (time: any, _tz: string = defaultTimezone) => {
  const tzOffset = dayjs.tz(time, _tz).utcOffset();
  return dayjs(time)
    .subtract(tzOffset, "minute")
    .add(clientTimeOffset, "minute")
    .valueOf();
};

// Convert time to UTC +-0 time
export const getClientUTCTime = (time: any) => {
  return dayjs(time).add(clientTimeOffset, "minute").valueOf();
};

// Convert time from UTC +- 0 -> Timezone time -> Local user time
// * Note: Not using dayjs.tz because of validate input using local time
export const initDate = (time: any, _tz: string = defaultTimezone) => {
  if (!time) return undefined;
  const tzOffset = dayjs.tz(time, _tz).utcOffset();
  return dayjs(time)
    .add(tzOffset, "minute")
    .subtract(clientTimeOffset, "minute");
};

export const getMonth = (date: any) => {
  return date.format("MMMM");
};

export const getYear = (date: any) => {
  return Number(date.format("YYYY"));
};

export const getDayjsFromYear = (year?: number) => {
  if (!year) return dayjs();
  return dayjs(String(year), "YYYY");
};

export const getDayjsFromMonth = (month?: string) => {
  if (!month) return dayjs();
  return dayjs(month, "MMMM");
};

export const getRelativeTime = (time: any) => {
  return !time ? null : dayjs(time).fromNow();
};

export const getCurrentUTCTime = (_tz: string = defaultTimezone) => {
  const time = dayjs.utc().format(formatType);
  const tzOffset = dayjs.tz(time, _tz).utcOffset();
  return dayjs(time).add(tzOffset, "minute");
};

export const getLeftTime = (time: any, _tz: string = defaultTimezone) => {
  const deadline = dayjs.tz(dayjs.utc(time), _tz);
  const current = getCurrentUTCTime(_tz);

  const distance = deadline.valueOf() - current.valueOf();
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((distance / 1000 / 60) % 60);
  const seconds = Math.floor((distance / 1000) % 60);

  if (days <= 0) {
    if (hours <= 0) {
      if (minutes <= 0) {
        return `${seconds} more seconds to`;
      }
      return `${minutes} more minutes to`;
    }
    return `${hours} more hours to`;
  }
  return `${days} more days to`;
};

export const getLeftTimeV2 = (time: any, _tz: string = defaultTimezone) => {
  const deadline = dayjs.tz(dayjs.utc(time), _tz);
  const current = getCurrentUTCTime(_tz);

  const distance = deadline.valueOf() - current.valueOf();
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((distance / 1000 / 60) % 60);
  const seconds = Math.floor((distance / 1000) % 60);

  if (days <= 0) {
    if (hours <= 0) {
      if (minutes <= 0) {
        return `${seconds} seconds left`;
      }
      return `${minutes} minutes left`;
    }
    return `${hours} hours left`;
  }
  return `${days} days left`;
};

export const getStartEndFormat = (
  start?: number | Dayjs,
  end?: number | Dayjs,
  _tz: string = defaultTimezone
) => {
  let time = dayjs(start).format("MMMM D, YYYY");
  if (end) {
    if (!dayjs(start).isSame(dayjs(end), "year")) {
      return `${dayjs(start).format("MMM D, YYYY")} - ${dayjs(end).format(
        "MMM D, YYYY"
      )}`;
    }

    if (dayjs(start).isSame(dayjs(end), "month")) {
      if (dayjs(start).isSame(dayjs(end), "day")) {
        time = `${dayjs(start).format("MMM D, YYYY")}`;
      } else {
        time = `${dayjs(start).format("MMM D")} - ${dayjs(end).format(
          "D, YYYY"
        )}`;
      }
    } else {
      time = `${dayjs(start).format("MMM D")} - ${dayjs(end).format(
        "MMM D, YYYY"
      )}`;
    }
  }
  return time;
};

export interface ITimezoneFormat {
  location: string;
  text: string;
  value: string;
  utc: string;
}

export const getTzFormat = (tz?: string): ITimezoneFormat => {
  if (!tz)
    return {
      location: "Central Time",
      text: "(GMT-05:00) Central Time",
      value: defaultTimezone,
      utc: "GMT-05:00",
    };
  const index = tz?.indexOf("_");
  const text = tz?.substr(0, index) || "(GMT-05:00) Central Time";
  const value = tz?.substr(index + 1) || defaultTimezone;

  const indexLocation = text?.indexOf(")");
  const location = text?.substr(indexLocation + 1) || "Central Time";
  const utc = text?.substr(0, indexLocation)?.substring(1) || "GMT-05:00";

  return {
    location: location && location?.includes("(") ? location + ")" : location,
    text: text,
    value: value,
    utc: utc,
  };
};

export function getCurrentTimeZone() {
  var offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    "GMT" +
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}
