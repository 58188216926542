import axios from "api/axios";

import {
  IUserData,
  ISignInParams,
  ISignUpParams,
  ICreateForgotPasswordCodeParams,
  IVerifyEmailParams,
  IResendVerifyEmailParams,
  IUpdateForgotPasswordParams,
  IFollowUserParams,
} from "./types";

const baseURL = process.env.REACT_APP_API_URI;
export const refreshTokenRequest = async () => {
  return await axios.post(baseURL + "/auth/refresh_token");
};

export const signUpRequest = async (params: ISignUpParams) => {
  return await axios({
    url: "/auth/sign_up",
    method: "POST",
    data: params,
  });
};

export const signInRequest = async (params: ISignInParams) => {
  return await axios({
    url: "/auth/sign_in",
    method: "POST",
    data: params,
  });
};

export const logoutRequest = async () => {
  return await axios({
    url: "/auth/log_out",
    method: "POST",
  });
};

export const getUserRequest = async (): Promise<IUserData> => {
  const { data } = await axios({
    url: `/user/me`,
    method: "GET",
  });
  return data;
};

export const getUserByIdRequest = async (
  userId: string
): Promise<IUserData> => {
  const { data } = await axios({
    url: `/user/get_by_id/${userId}`,
    method: "GET",
  });
  return data;
};

export const createForgotPasswordCode = async (
  params: ICreateForgotPasswordCodeParams
) => {
  return await axios({
    url: "/user/create_forgot_password_code",
    method: "PUT",
    data: params,
  });
};

export const updateForgotPasswordRequest = async (
  params: IUpdateForgotPasswordParams
) => {
  return await axios({
    url: "/user/forgot_password",
    method: "PUT",
    data: params,
  });
};

export const verifyChangeEmailRequest = async (params: IVerifyEmailParams) => {
  return await axios({
    url: "/user/verify_new_email",
    method: "PUT",
    data: params,
  });
};

export const acceptFollowUserRequest = async (params: IFollowUserParams) => {
  return await axios({
    url: "/user/accept_request_follow_user",
    method: "PUT",
    data: params,
  });
};

export const dismissFollowUserRequest = async (params: IFollowUserParams) => {
  return await axios({
    url: "/user/dismiss_request_follow_user",
    method: "PUT",
    data: params,
  });
};

export const verifyEmailRequest = async (params: IVerifyEmailParams) => {
  return await axios({
    url: "/user/verify_email",
    method: "PUT",
    data: params,
  });
};

export const resendVerifyEmailRequest = async (
  params: IResendVerifyEmailParams
) => {
  return await axios({
    url: "/user/resend_verify_email",
    method: "PUT",
    data: params,
  });
};

export const resendVerifyChangeEmailRequest = async (
  params: IResendVerifyEmailParams
) => {
  return await axios({
    url: "/user/resend_verify_new_email",
    method: "PUT",
    data: params,
  });
};

export const deleteAccountRequest = async () => {
  return await axios({
    url: "/user/delete_user",
    method: "DELETE",
  });
};

export const getHackathonId = async (
  subdomain: string
): Promise<{ _id: string }> => {
  const { data } = await axios({
    url: `/hackathon/find_hackathon_id/${subdomain}`,
    method: "GET",
  });
  return data;
};
