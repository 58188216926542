import { useState } from "react";
import { Drawer, Button, Menu, Space } from "antd";
import styles from "./styles.module.css";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { routesEnum } from "pages/Routes";
import { useAppSelector } from "hooks";
import { getAuthRefresh } from "store/ducks/auth/slice";
import UserDropdown from "./UserDropdown";
import Notification from "./Notification";

const SideBar = () => {
  const isLoggedIn = useAppSelector(getAuthRefresh);

  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const toggleOpen = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };

  const onMenuClick = (e: any) => {
    history.push(e.key);
    setVisible(false);
  };

  return (
    <>
      <Button
        className={clsx(styles.burger)}
        shape="circle"
        type="text"
        onClick={toggleOpen}
      >
        <span />
      </Button>
      <Drawer
        bodyStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Menu onClick={onMenuClick} className={styles.menu} mode="inline">
          {!isLoggedIn ? (
            <>
              <Menu.Item key={routesEnum.signIn}>Login</Menu.Item>
              <Menu.Item key={routesEnum.signUp}>Sign Up</Menu.Item>
            </>
          ) : (
            <div className={styles.mobileUser}>
              <UserDropdown />
            </div>
          )}
          {isLoggedIn && (
            <Menu.Item key={routesEnum.createHackathon}>
              Host a Hackathon
            </Menu.Item>
          )}
          <Menu.Item key={routesEnum.home}>Hackathon</Menu.Item>
          <Menu.Item key={routesEnum.blog}>Blog</Menu.Item>
          <Menu.Item key={routesEnum.software}>Projects</Menu.Item>
        </Menu>
      </Drawer>
    </>
  );
};

export default SideBar;
