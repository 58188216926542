import { FC } from "react";

const UploadIcon: FC<{ color?: string; className?: string }> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1440_141)">
        <path
          d="M13.334 13.3334L10.0007 10L6.66732 13.3334"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.001 17.5L10.001 10"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4003 15.0749C18.1248 14.5655 18.6681 13.8384 18.9514 12.9992C19.2347 12.1601 19.2431 11.2525 18.9756 10.4081C18.7081 9.5638 18.1785 8.82669 17.4637 8.30375C16.7489 7.7808 15.886 7.49925 15.0003 7.49994H13.9503C13.6997 6.52317 13.2307 5.61598 12.5787 4.84668C11.9268 4.07737 11.1088 3.46599 10.1863 3.05857C9.26387 2.65115 8.26101 2.4583 7.25325 2.49454C6.24548 2.53078 5.25907 2.79517 4.36827 3.2678C3.47747 3.74043 2.7055 4.40898 2.11047 5.22314C1.51544 6.03729 1.11285 6.97582 0.933028 7.96807C0.753202 8.96032 0.800819 9.98044 1.07229 10.9516C1.34377 11.9228 1.83203 12.8198 2.50031 13.5749"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1440_141">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UploadIcon;
