import { FC } from "react";
import styles from "./Wallet.module.css";
import { Space } from "antd";

import { useAppDispatch } from "hooks";
import { toggleWalletModal } from "store/ducks/wallet-modal/slice";
import { GradientButton } from "components/GradientButton";
import { formatNumber } from "utils/number";
import { useWalletInfo } from "hooks/web3Hooks";
import { CHAINS } from "utils/web3/Chain";

const WalletConnector: FC = () => {
  const dispatch = useAppDispatch();
  const { balance, account, chainId } = useWalletInfo();

  const handleOpenWalletConnect = () => {
    dispatch(toggleWalletModal());
  };

  if (!account) {
    return (
      <GradientButton size="small" onClick={handleOpenWalletConnect}>
        Connect Wallet
      </GradientButton>
    );
  }

  return (
    <Space className={styles.balance} align="center" size={7}>
      <label>Balance:</label>
      <span>
        {formatNumber(balance, 4)} {CHAINS[chainId ?? 1].nativeCurrency.symbol}
      </span>
    </Space>
  );
};

export default WalletConnector;
