import { FC } from "react";

const ComingSoon: FC<{ active?: boolean }> = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.817 8.48479L11.4107 8.48413L11.4083 14.1095H17.0357V12.7032H12.8151L12.817 8.48479Z"
        fill={active ? "white" : "#080A25"}
      />
      <path
        d="M17.9148 6.34748L19.1426 4.59408L17.9906 3.7875L16.7637 5.53973C15.5906 4.84364 14.2496 4.40189 12.817 4.29239V2.85938H14.2232V1.45312H12.817H11.4107H10.0045V2.85938H11.4107V4.29239C9.97809 4.40189 8.63714 4.84364 7.464 5.53973L6.23709 3.7875L5.08519 4.59408L6.31294 6.34748C5.16863 7.28958 4.25672 8.50406 3.67678 9.89062L3.21937 11.2969C3.11109 11.7535 3.0367 12.2231 3.00005 12.7031L3 14.1094C3.0367 14.5894 3.11105 15.059 3.21933 15.5156L3.67713 16.9219C5.05741 20.222 8.31886 22.5469 12.1139 22.5469C17.154 22.5469 21.2545 18.4464 21.2545 13.4062C21.2545 10.5666 19.9528 8.02528 17.9148 6.34748ZM12.1139 21.1406C9.11423 21.1406 6.50798 19.4242 5.22567 16.9219H7.19199V15.5156H4.67189C4.54322 15.0623 4.45552 14.5921 4.41178 14.1094H5.78573V12.7031H4.41178C4.45552 12.2204 4.54322 11.7502 4.67189 11.2969H7.19199V9.89062H5.22567C6.50798 7.38834 9.11423 5.67188 12.1139 5.67188C16.3786 5.67188 19.8482 9.14152 19.8482 13.4062C19.8482 17.671 16.3786 21.1406 12.1139 21.1406Z"
        fill={active ? "white" : "#080A25"}
      />
    </svg>
  );
};

export default ComingSoon;
