import {
  AURORA_RPC,
  CHAIN_BY_NETWORK_KEYS,
  ETH_RPC,
  POLYGON_RPC,
} from "../constant";

export interface IChain {
  chainId: number;
  getExplorerAddressLink: (address: string) => string;
  getExplorerTransactionLink: (address: string) => string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
}

const etherscanUrl = "https://etherscan.io";
const ropstenEtherscanUrl = "https://ropsten.etherscan.io";
const rinkebyEtherscanUrl = "https://rinkeby.etherscan.io";
const goerliEtherscanUrl = "https://goerli.etherscan.io";
const kovanEtherscanUrl = "https://kovan.etherscan.io";
const polygonScanUrl = "https://polygonscan.com";
const mumbaiScanUrl = "https://mumbai.polygonscan.com";
const auroraTestScanUrl = "https://testnet.aurorascan.dev";
const auroraScanUrl = "https://aurorascan.dev";

export const CHAINS: Record<number, IChain> = {
  1: {
    chainName: "Ethereum",
    chainId: 1,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [ETH_RPC],
    getExplorerAddressLink: (address: string) =>
      `${etherscanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${etherscanUrl}/tx/${transactionHash}`,
  },
  3: {
    chainName: "Ropsten",
    chainId: 3,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [ETH_RPC],
    getExplorerAddressLink: (address: string) =>
      `${ropstenEtherscanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${ropstenEtherscanUrl}/tx/${transactionHash}`,
  },
  4: {
    chainName: "Rinkeby",
    chainId: 4,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [ETH_RPC],
    getExplorerAddressLink: (address: string) =>
      `${rinkebyEtherscanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${rinkebyEtherscanUrl}/tx/${transactionHash}`,
  },
  5: {
    chainName: "Goerli",
    chainId: 5,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [ETH_RPC],
    getExplorerAddressLink: (address: string) =>
      `${goerliEtherscanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${goerliEtherscanUrl}/tx/${transactionHash}`,
  },
  42: {
    chainName: "Kovan",
    chainId: 42,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [ETH_RPC],
    getExplorerAddressLink: (address: string) =>
      `${kovanEtherscanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${kovanEtherscanUrl}/tx/${transactionHash}`,
  },
  137: {
    chainName: "Polygon",
    chainId: 137,
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [POLYGON_RPC],
    getExplorerAddressLink: (address: string) =>
      `${polygonScanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${polygonScanUrl}/tx/${transactionHash}`,
  },
  80001: {
    chainName: "Mumbai",
    chainId: 80001,
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [POLYGON_RPC],
    getExplorerAddressLink: (address: string) =>
      `${mumbaiScanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${mumbaiScanUrl}/tx/${transactionHash}`,
  },
  1313161554: {
    chainName: "Aurora",
    chainId: 1313161554,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [AURORA_RPC],
    getExplorerAddressLink: (address: string) =>
      `${auroraScanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${auroraScanUrl}/tx/${transactionHash}`,
  },
  1313161555: {
    chainName: "Aurora Testnet",
    chainId: 1313161555,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [AURORA_RPC],
    getExplorerAddressLink: (address: string) =>
      `${auroraTestScanUrl}/address/${address}`,
    getExplorerTransactionLink: (transactionHash: string) =>
      `${auroraTestScanUrl}/tx/${transactionHash}`,
  },
};

export const getNativeCurrency = (network?: string) => {
  return CHAINS[CHAIN_BY_NETWORK_KEYS[network || "Ethereum"]].nativeCurrency
    .symbol;
};

export const getTxUrl = (network?: string, tx?: string) => {
  return CHAINS[
    CHAIN_BY_NETWORK_KEYS[network || "Ethereum"]
  ].getExplorerTransactionLink(tx ?? "");
};
