import React from "react";

const Pocket = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33332 2.5H16.6667C17.1087 2.5 17.5326 2.67559 17.8452 2.98816C18.1577 3.30072 18.3333 3.72464 18.3333 4.16667V9.16667C18.3333 11.3768 17.4553 13.4964 15.8925 15.0592C14.3297 16.622 12.2101 17.5 9.99999 17.5C8.90564 17.5 7.82201 17.2845 6.81096 16.8657C5.79991 16.4469 4.88125 15.833 4.10743 15.0592C2.54463 13.4964 1.66666 11.3768 1.66666 9.16667V4.16667C1.66666 3.72464 1.84225 3.30072 2.15481 2.98816C2.46737 2.67559 2.8913 2.5 3.33332 2.5V2.5Z"
        stroke="#080A25"
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66666 8.33301L9.99999 11.6663L13.3333 8.33301"
        stroke="#080A25"
        strokeOpacity="0.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pocket;
