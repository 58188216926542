import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Suspense } from "react";

import reportWebVitals from "./reportWebVitals";
import store from "./store";
import App from "./App";
import ProgressBar from "components/Loading/ProgressBar";

ReactDOM.render(
  <Suspense fallback={<ProgressBar />}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
