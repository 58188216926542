import { FC } from "react";
import { routesEnum } from "pages/Routes";
import { useLocation } from "react-router-dom";

import { useDomainCheck } from "hooks/useDomainCheck";
import BlankLayout from "./BlankLayout";
import Layout from "./Layout";

const blankLayoutRoutes = [
  routesEnum.resetPassword,
  routesEnum.changePassword,
  routesEnum.verifyEmail,
  routesEnum.updateEligibility,
  routesEnum.notFound,
];

export const MainLayout: FC = ({ children }) => {
  const location = useLocation();
  useDomainCheck();
  const isInBlankList =
    blankLayoutRoutes.includes(location.pathname as any) ||
    location.pathname.includes(`${routesEnum.printJudgingSheet}/`);

  if (isInBlankList) {
    return <BlankLayout>{children}</BlankLayout>;
  }

  return <Layout>{children}</Layout>;
};
